import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PartnerSlide from "./PartnerSlide";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// import home from "../assets/home.jpg";
import styled from "@emotion/styled";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ImageSlider from "./ImageSlider";
import ServiceSection from "./ServiceSection";
import ProductSection from "./ProductSection";
import ProjectSection from "./ProjectSection";
import CustomerReview from "./CustomerReview";
import BlogSection from "./BlogSection";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import homeVector from "../assets/home-vector.svg";
import img1 from "../assets/P1_444Brennan-008.jpg";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";

// const StyledShowRoomButton = styled(Button)({
//   color: "#1E1F24",
//   backgroundColor: "#fefbf3",
//   border: "1px solid white",
//   "&:hover": {
//     backgroundColor: "#000000",
//     color: "#FFFFFF",
//   },
//   borderRadius: "30px",
//   opacity: 0.8,
// });
const StyledShowRoomButton2 = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  borderRadius: "30px",
});

// const StyledQuoteButton = styled(Button)({
//   color: "#1E1F24",
//   border: "1px solid #1E1F24",
//   backgroundColor: "#F3950D",
//   "&:hover": {
//     backgroundColor: "white",
//     color: "#000000",
//   },
//   borderRadius: "30px",
// });

// const StyledMoreAboutUsButton = styled(Button)({
//   color: "#1E1F24",
//   border: "1px solid #1E1F24",
//   backgroundColor: "#F3950D",
//   "&:hover": {
//     backgroundColor: "#000000",
//     color: "#FFFFFF",
//   },
//   borderRadius: "30px",
// });

const StyledViewGalleryButton = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  borderRadius: "30px",
  padding: "0.5rem 3rem",
});

const StyledTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: black;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #aa6800;
      }
    }
    & .MuiFormHelperText-root {
      color: red;
    }
    & .MuiInput-underline:after {
      border-bottom-color: #aa6800;
    }
  }
`;

const StyledSubmitButton = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  borderRadius: "30px",
});

// const StyledHomeBox = styled(Box)(({ theme }) => ({
//   [theme.breakpoints.between("xs", "sm")]: {
//     height: "50vh",
//     margin: "7rem 0.5rem 1rem 0.5rem",
//   },

//   [theme.breakpoints.between("sm", "lg")]: {
//     // backgroundColor: "blue",
//     height: "45vh",
//     margin: "7rem 0.5rem 1rem 0.5rem",
//     padding: "6rem 0 0 0",
//   },

//   [theme.breakpoints.up("lg")]: {
//     height: "100vh",
//     margin: "7rem 2rem 1rem 2rem",
//     padding: "18rem 0 0 0",
//   },
// }));

const Home = () => {
  const navigate = useNavigate();
  const { isDevice } = useScreenSizes();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("2024-01-01");
  const [time, setTime] = useState("9:00AM");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEventTimeChange = (event: any) => {
    setTime(event.target.value);
  };

  const timeOptions = [
    "9:00AM",
    "9:15AM",
    "9:30AM",
    "9:45AM",
    "10:00AM",
    "10:15AM",
    "10:30AM",
    "10:45AM",
    "11:00AM",
    "11:15AM",
    "11:30AM",
    "11:45AM",
    "12:00PM",
    "12:15PM",
    "12:30PM",
    "12:45PM",
    "1:00PM",
    "1:15PM",
    "1:30PM",
    "1:45PM",
    "2:00PM",
    "2:15PM",
    "2:30PM",
    "2:45PM",
    "3:00PM",
    "3:15PM",
    "3:30PM",
    "3:45PM",
    "4:00PM",
    "4:15PM",
    "4:30PM",
    "4:45PM",
    "5:00PM",
    "5:15PM",
    "5:30PM",
    "5:45PM",
    "6:00PM",
  ];

  // const handleClick = () => {
  //   const anchor = document.querySelector("#project-section");

  //   if (anchor) {
  //     anchor.scrollIntoView({
  //       behavior: "smooth", // Add smooth scrolling behavior
  //       block: "start", // Scroll to the top of the viewport
  //     });
  //   }
  // };

  return (
    <>
      <Box sx={{ overflowX: "hidden" }} p={isDevice ? 0 : "0 4rem"}>
        {isDevice && (
          <Box width={"100%"}>
            <img
              src={img1}
              style={{ width: "100%", objectFit: "cover" }}
              alt=""
            />
          </Box>
        )}

        {/* {isDevice && (
          <Box
            textAlign={"center"}
            display="flex"
            flexDirection="column"
            gap={"1rem"}
          >
            <Box
              padding={"0 1rem"}
              display="flex"
              flexDirection="column"
              gap="1rem"
            >
              <Typography
                variant="subtitle2"
                color="#AA6800"
                m="1.5rem 0 -0.5rem 0"
                fontWeight="bold"
              >
                SAN BRUNO GROUP.
              </Typography>
              <Box display="flex" justifyContent={"center"} gap="1rem">
                <Box>
                  <img src={line7} alt="" />
                </Box>
                <Typography variant="subtitle1" color="#1E1F24">
                  Why SBG?
                </Typography>
                <Box>
                  <img src={line6} alt="" />
                </Box>
              </Box>
              <Typography variant="body1" color="#1E1F24" textAlign={"left"}>
                SBG serves Architects, Designers, Builders and Homeowners by
                offering a full service approach to Windows and Doors that will
                excite your aesthetic and exceed your quality expectations.
              </Typography>
            </Box>
            <Box>
              <Box>
                <ImageSlider />
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  textAlign={"left"}
                  padding={"0 0.5rem"}
                  mt={"2rem"}
                >
                  Canada's largest companies trust the San Bruno Group
                </Typography>
                <PartnerSlide />
              </Box>
            </Box>
            <Box
              display="flex"
              gap="1rem"
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography variant="subtitle2" color="#AA6800" fontWeight="bold">
                Who We Are
              </Typography>
              <Box display="flex" justifyContent={"center"} gap="1rem">
                <Box>
                  <img src={line7} alt="" />
                </Box>
                <Typography variant="subtitle1" color="#1E1F24">
                  About Us
                </Typography>
                <Box>
                  <img src={line6} alt="" />
                </Box>
              </Box>
              <Typography variant="body1" color="#1E1F24" textAlign={"left"}>
                Our team has devoted more than a decade to providing service
                exclusively to builders, contractors and homeowners who have
                chosen Elite Windows and Doors for their homes and products.
              </Typography>
              <Typography variant="body1" color="#1E1F24" textAlign={"left"}>
                We're increasing curb appeal, improving efficiency, and
                enhancing quality of life one home at a time.
              </Typography>
              <Box width={"13.22rem"}>
                <StyledMoreAboutUsButton
                  variant="contained"
                  startIcon={<ArrowOutwardIcon />}
                  fullWidth
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/about");
                  }}
                >
                  <Typography variant="button">More About Us</Typography>
                </StyledMoreAboutUsButton>
              </Box>
            </Box>
          </Box>
        )} */}
        <>
          <Box
            textAlign={"center"}
            display="flex"
            flexDirection={"column"}
            gap={"1rem"}
            mt="2rem"
            width={"100%"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              width={"100%"}
              justifyContent={"space-evenly"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"1rem"}
                maxWidth={isDevice ? "100%" : "600px"}
                padding={"0 1rem"}
              >
                <Typography
                  variant="subtitle1"
                  color="#AA6800"
                  m="1.5rem 0 -0.5rem 0"
                  fontWeight="bold"
                  textAlign={"left"}
                >
                  SAN BRUNO GROUP.
                </Typography>

                <Typography
                  textAlign={"left"}
                  variant={isDevice ? "h5" : "h4"}
                  color="#1E1F24"
                >
                  Enhancing Living Spaces with {!isDevice && <br />} Premium
                  Windows & Doors
                </Typography>
                <Typography
                  variant={isDevice ? "body1" : "subtitle2"}
                  color="#1E1F24"
                  textAlign={"left"}
                  lineHeight={isDevice ? "1.5rem" : "1.75rem"}
                >
                  SBG serves Architects, Designers, Builders and Homeowners by
                  offering a full service approach to Windows and Doors that
                  will excite your aesthetic and exceed your quality
                  expectations.
                </Typography>
                <Box
                  display={"flex"}
                  gap="0rem"
                  alignItems={"end"}
                  justifyContent={"start"}
                >
                  <Box width={isDevice ? "16rem" : "18rem"}>
                    <StyledShowRoomButton2
                      variant="contained"
                      startIcon={<ArrowOutwardIcon />}
                      fullWidth
                      sx={{
                        padding: "1rem",
                      }}
                      onClick={() => {
                        handleOpen();
                      }}
                    >
                      <Typography variant="button">
                        Visit Our Showroom
                      </Typography>
                    </StyledShowRoomButton2>
                  </Box>
                  <Box width={"4rem"}>
                    <img src={homeVector} color="black" width="100%" alt="" />
                  </Box>
                </Box>
              </Box>
              {!isDevice && (
                <Box width={"700px"}>
                  <img
                    src={img1}
                    alt=""
                    style={{ width: "100%", borderRadius: "30px" }}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <ProductSection />
        </>
      </Box>
      {isDevice && (
        <>
          <ProjectSection />
          <ServiceSection />
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"2rem"}
            alignItems={"center"}
            bgcolor="#E0E1E6"
            p="1rem 0"
            mt="1rem"
          >
            <Typography variant="h5" fontWeight="bold" textAlign={"center"}>
              Explore Creative Inspirations
              <br /> Dive into{" "}
              <strong style={{ color: "#AA6800" }}>Our Gallery </strong>
              Collection for Windows and Doors
            </Typography>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <ImageSlider />
            </Box>
            <Box>
              <StyledViewGalleryButton
                endIcon={<ArrowOutwardIcon />}
                onClick={() => {
                  navigate("/gallery");
                  window.scrollTo(0, 0);
                }}
              >
                <Typography variant="button">View Gallery</Typography>
              </StyledViewGalleryButton>
            </Box>
          </Box>
          <CustomerReview />
          <BlogSection />
          <Box display={"flex"} flexDirection={"column"} gap={"1rem"} mb="8rem">
            <Typography variant="h5" textAlign={"center"}>
              Meet Our Partners
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign={"center"}
              sx={{ color: "#AA6800", opacity: 0.7 }}
            >
              San Bruno's Trusted Partners: Leading Construction Builders Rely
              on <br /> Our Sales, Installation, and Consulting Services
            </Typography>
            <PartnerSlide />
          </Box>
        </>
      )}
      {!isDevice && (
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            bgcolor="#FFEFDD"
          >
            <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
              <Typography variant="h3">
                Entrust the{" "}
                <span style={{ color: "#AA6800" }}>San Bruno Group</span> to{" "}
                <br /> install your new Windows and Doors.
              </Typography>
              <Typography variant="subtitle1">
                Unrivaled Elegance, Sleek Design, Infinite Possibilities,
                Affordable Luxury.
              </Typography>
            </Box>
            <ProjectSection />
          </Box>
          <ServiceSection />
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"2rem"}
            alignItems={"center"}
            bgcolor="#E0E1E6"
            p="2rem 0"
          >
            <Typography variant="h5" fontWeight="bold" textAlign={"center"}>
              Explore Creative Inspirations
              <br /> Dive into{" "}
              <strong style={{ color: "#AA6800" }}>Our Gallery </strong>
              Collection for Windows and Doors
            </Typography>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <ImageSlider />
            </Box>
            <Box>
              <StyledViewGalleryButton
                endIcon={<ArrowOutwardIcon />}
                onClick={() => {
                  navigate("/gallery");
                  window.scrollTo(0, 0);
                }}
              >
                <Typography variant="button">View Gallery</Typography>
              </StyledViewGalleryButton>
            </Box>
          </Box>

          <CustomerReview />
          <Box display={"flex"} flexDirection={"column"} gap={"2rem"} mb="8rem">
            <Typography variant="h3" textAlign={"center"}>
              Meet Our Partners
            </Typography>
            <Typography
              variant="h6"
              textAlign={"center"}
              sx={{ color: "#AA6800", opacity: 0.7 }}
            >
              San Bruno's Trusted Partners: Leading Construction Builders Rely
              on <br /> Our Sales, Installation, and Consulting Services
            </Typography>
            <PartnerSlide />
          </Box>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Visit Our Showroom
            </Typography>
            <Button onClick={handleClose} sx={{ color: "#1E1F24" }}>
              <CloseIcon />
            </Button>
          </Box>
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Box display="flex" gap="1rem" mt="1rem">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {isDevice && (
                  <MobileDatePicker
                    label="Date"
                    defaultValue={dayjs("2024-01-01")}
                    onChange={(selectedDate) => {
                      setDate(
                        selectedDate?.format("DD-MM-YYYY") ||
                          new Date().getDate().toString()
                      );
                    }}
                    value={dayjs(date)}
                  />
                )}
                {!isDevice && (
                  <DesktopDatePicker
                    label="Date"
                    defaultValue={dayjs(date)}
                    onChange={(selectedDate) => {
                      setDate(
                        selectedDate?.format("DD-MM-YYYY") ||
                          new Date().getDate().toString()
                      );
                    }}
                    value={dayjs(date)}
                  />
                )}
              </LocalizationProvider>
              <Box width="40%">
                <FormControl fullWidth>
                  <InputLabel
                    id="event-time-label"
                    sx={{
                      "&.Mui-focused": {
                        color: "#AA6800",
                      },
                    }}
                  >
                    Time
                  </InputLabel>
                  <Select
                    labelId="event-time-label"
                    id="event-time-select"
                    value={time}
                    label="Time"
                    onChange={handleEventTimeChange}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#AA6800",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#AA6800",
                      },
                    }}
                  >
                    {timeOptions.map((time) => (
                      <MenuItem key={time} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <StyledTextField
              margin="normal"
              fullWidth
              label="Message"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <Box display={"flex"} justifyContent={"center"} mt={"1rem"}>
              <StyledSubmitButton fullWidth endIcon={<ArrowOutwardIcon />}>
                <Typography variant="button">Submit</Typography>
              </StyledSubmitButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Home;
