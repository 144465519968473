import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import ProductDisplay from "./ProductDisplay";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import styled from "@emotion/styled";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";

const StyledBrowseProductsButton = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  borderRadius: "30px",
});

const ProductSection = () => {
  const navigate = useNavigate();
  const { isDevice } = useScreenSizes();

  const handleClick = () => {
    window.scrollTo(0, 0);
    navigate("/products");
  };
  return (
    <Box
      textAlign={"left"}
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
      padding={isDevice ? "0 1rem" : "0 6rem"}
      mt="5rem"
    >
      <Box>
        <Typography variant={"subtitle1"} color="#111111" fontWeight="bold">
          OUR PRODUCTS
        </Typography>
        <Typography
          variant={"h4"}
          color="#AA6800"
          mb="0.5rem"
          fontWeight="bold"
        >
          Precision-built
          <br /> Windows and Doors
        </Typography>
      </Box>
      <Typography variant={"subtitle2"} color="#1E1F24" fontWeight="regular">
        Inspire your aesthetic senses <br /> and surpass your expectations with
        our commitment to quality.
      </Typography>
      <ProductDisplay />
      <Box
        width={isDevice ? "14rem" : "16rem"}
        margin={isDevice ? "0 auto" : "2rem auto 1rem auto"}
      >
        <StyledBrowseProductsButton
          variant="contained"
          startIcon={<ArrowOutwardIcon />}
          onClick={() => {
            handleClick();
          }}
          fullWidth
          sx={{ p: isDevice ? "" : "0.75rem" }}
        >
          <Typography variant="button">Browse all Products</Typography>
        </StyledBrowseProductsButton>
      </Box>
    </Box>
  );
};

export default ProductSection;
