import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Typography,
  AppBar,
  Container,
  Toolbar,
  Fade,
  Fab,
  Button,
  Link,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import lionHead from "../assets/lion-head.svg";
import SideDrawer from "./SideDrawer";
import { ReactComponent as Telephone } from "../assets/telephone.svg";
import styled from "@emotion/styled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledButton = styled(Button)({
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
});

const NavBar = () => {
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const navigate = useNavigate();
  const { isDevice } = useScreenSizes();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: window,
    threshold: 100,
  });

  const handleClick = () => {
    const anchor = document.querySelector("#top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth", // Add smooth scrolling behavior
        block: "start", // Scroll to the top of the viewport
      });
    }
  };

  const [productsMenuAnchor, setProductsMenuAnchor] =
    React.useState<null | HTMLElement>(null);
  const [companyMenuAnchor, setCompanyMenuAnchor] =
    React.useState<null | HTMLElement>(null);

  const openProducts = Boolean(productsMenuAnchor);
  const openCompany = Boolean(companyMenuAnchor);

  const handleOpenProductsMenu = (event: any) => {
    setProductsMenuAnchor(event.currentTarget);
  };

  const handleCloseProductsMenu = () => {
    setProductsMenuAnchor(null);
  };

  const handleOpenCompanyMenu = (event: any) => {
    setCompanyMenuAnchor(event.currentTarget);
  };

  const handleCloseCompanyMenu = () => {
    setCompanyMenuAnchor(null);
  };

  return (
    <>
      <Box id="top-anchor">
        <AppBar
          position="fixed"
          elevation={3}
          component={"nav"}
          sx={{
            backgroundColor: "#393232",
            padding: "0",
            overflow: "hidden",
          }}
        >
          <Container sx={{ padding: "0", overflow: "hidden" }} maxWidth="xl">
            <Toolbar disableGutters sx={{ overflow: "hidden" }}>
              <Box
                padding="0.25rem"
                display="flex"
                width={"100%"}
                sx={{ overflow: "hidden" }}
                gap={"auto"}
              >
                <Box
                  display="flex"
                  gap="0.2rem"
                  alignItems={"center"}
                  onClick={() => navigate("/")}
                  sx={{ cursor: "pointer" }}
                  flexShrink={0}
                >
                  <img src={lionHead} alt="" width="50px" height="50px" />
                  <Typography
                    variant="subtitle1"
                    color="white"
                    fontWeight="700"
                    display="inline-block"
                    fontFamily={"Fraunces"}
                  >
                    SAN BRUNO GROUP
                  </Typography>
                </Box>
                {isDevice && (
                  <Box
                    display="grid"
                    gridTemplateColumns={"repeat(3, 30px)"}
                    alignItems={"center"}
                    textAlign={"center"}
                    margin={"0 auto"}
                  >
                    <Box>
                      <Link
                        href="tel:6132967866"
                        textTransform={"none"}
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <Box>
                          <Telephone
                            style={{
                              width: "30px",
                              height: "30px",
                              transform: "rotate(250deg)",
                            }}
                          />
                        </Box>
                      </Link>
                    </Box>

                    <Box>
                      <Link
                        onClick={() =>
                          window.open(
                            "https://maps.app.goo.gl/xC5eyVNFECQi89LVA"
                          )
                        }
                        rel="noopener noreferrer"
                      >
                        <LocationOnIcon
                          style={{
                            color: "#F3950D",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      </Link>
                    </Box>
                    {/* <Box m="0 auto">
                    <StyledButton>
                      <RequestQuoteIcon
                        style={{
                          color: "#F3950D",
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </StyledButton>
                  </Box> */}

                    <Box>
                      <StyledButton
                        onClick={() => setToggleDrawer(!toggleDrawer)}
                      >
                        <MenuIcon
                          style={{
                            color: "#F3950D",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      </StyledButton>
                    </Box>
                  </Box>
                )}
                {!isDevice && (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    width={"70%"}
                  >
                    <Box>
                      <StyledButton onClick={handleOpenProductsMenu}>
                        <Typography
                          variant="button"
                          color="#FFFFFF
"
                        >
                          Products
                        </Typography>
                        {productsMenuAnchor ? (
                          <KeyboardArrowUpIcon
                            sx={{ color: "#F3950D", cursor: "pointer" }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={{ color: "#F3950D", cursor: "pointer" }}
                          />
                        )}
                      </StyledButton>

                      <Menu
                        id="products-menu"
                        anchorEl={productsMenuAnchor}
                        open={openProducts}
                        onClose={handleCloseProductsMenu}
                        sx={{
                          "& .MuiList-root": {
                            backgroundColor: "#fefdfb",
                            width: "130px",
                          },
                        }}
                      >
                        <Box>
                          <MenuItem
                            onClick={() => {
                              handleCloseProductsMenu();

                              navigate("/products/window");
                              window.scrollTo(0, 0);
                            }}
                            sx={{ color: "#393232" }}
                          >
                            <Typography variant="button">Windows</Typography>
                          </MenuItem>
                          <Divider sx={{ backgroundColor: "#393232" }} />
                          <MenuItem
                            onClick={() => {
                              handleCloseProductsMenu();

                              navigate("/products/door");
                              window.scrollTo(0, 0);
                            }}
                            sx={{ color: "#393232" }}
                          >
                            <Typography variant="button">Doors</Typography>
                          </MenuItem>
                        </Box>
                      </Menu>
                    </Box>
                    <Box>
                      <StyledButton
                        onClick={() => {
                          navigate("/services");
                          window.scrollTo(0, 0);
                        }}
                      >
                        <Typography variant="button" color="#FFFFFF">
                          Services
                        </Typography>
                      </StyledButton>
                    </Box>
                    <Box>
                      <StyledButton
                        onClick={() => {
                          navigate("/warranty");
                          window.scrollTo(0, 0);
                        }}
                      >
                        <Typography variant="button" color="#FFFFFF">
                          Warranty
                        </Typography>
                      </StyledButton>
                    </Box>
                    <Box>
                      <StyledButton
                        onClick={() => {
                          navigate("/gallery");
                          window.scrollTo(0, 0);
                        }}
                      >
                        <Typography variant="button" color="#FFFFFF">
                          Gallery
                        </Typography>
                      </StyledButton>
                    </Box>
                    <Box>
                      <StyledButton onClick={handleOpenCompanyMenu}>
                        <Typography variant="button" color="#FFFFFF">
                          Company
                        </Typography>
                        {companyMenuAnchor ? (
                          <KeyboardArrowUpIcon
                            sx={{ color: "#F3950D", cursor: "pointer" }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={{ color: "#F3950D", cursor: "pointer" }}
                          />
                        )}
                      </StyledButton>
                      <Menu
                        id="company-menu"
                        anchorEl={companyMenuAnchor}
                        open={openCompany}
                        onClose={handleCloseCompanyMenu}
                        sx={{
                          "& .MuiList-root": {
                            backgroundColor: "#fefdfb",
                            width: "130px",
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleCloseCompanyMenu();
                            navigate("/about");
                            window.scrollTo(0, 0);
                          }}
                          sx={{ color: "#393232" }}
                        >
                          <Typography variant="button">About us</Typography>
                        </MenuItem>
                        <Divider sx={{ backgroundColor: "#393232" }} />
                        <MenuItem
                          onClick={() => {
                            handleCloseCompanyMenu();
                            navigate("/our-process");
                            window.scrollTo(0, 0);
                          }}
                          sx={{ color: "#393232" }}
                        >
                          <Typography variant="button">Our Process</Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                    <Box>
                      <StyledButton
                        onClick={() => {
                          navigate("/blogs");
                          window.scrollTo(0, 0);
                        }}
                      >
                        <Typography variant="button" color="#FFFFFF">
                          Blog
                        </Typography>
                      </StyledButton>
                    </Box>
                    <Box>
                      <StyledButton
                        onClick={() => {
                          navigate("/faqs");
                          window.scrollTo(0, 0);
                        }}
                      >
                        <Typography variant="button" color="#FFFFFF">
                          FAQ
                        </Typography>
                      </StyledButton>
                    </Box>
                    <Box width="9rem">
                      <Button
                        variant="text"
                        sx={{ color: "#F3950D" }}
                        endIcon={<ArrowOutwardIcon fontSize="large" />}
                        onClick={() => {
                          navigate("/get-a-quote");
                          window.scrollTo(0, 0);
                        }}
                      >
                        <Typography variant="button">Get A Quote</Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
                <SideDrawer
                  toggleDrawer={toggleDrawer}
                  setToggleDrawer={setToggleDrawer}
                />
              </Box>
            </Toolbar>
          </Container>
          <Box
            bgcolor="#1E1F24"
            width="100%"
            display="flex"
            alignItems={"center"}
            justifyContent={isDevice ? "space-evenly" : "center"}
            gap="0.5rem"
          >
            <Box display={"flex"} gap="0.15rem">
              <Box>
                <AccessTimeIcon fontSize="small" />
              </Box>
              <Box>
                <Typography variant="overline" textAlign={"center"}>
                  Currently open by appointment only.
                </Typography>
              </Box>
            </Box>
            {!isDevice && (
              <Box display={"flex"} alignItems={"center"}>
                <Box>
                  <StyledButton
                    onClick={() =>
                      window.open("https://maps.app.goo.gl/xC5eyVNFECQi89LVA")
                    }
                    rel="noopener noreferrer"
                  >
                    <LocationOnIcon
                      style={{
                        color: "#F3950D",
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  </StyledButton>
                </Box>
                <Link
                  href="tel:6132967866"
                  sx={{ textDecoration: "none", cursor: "pointer" }}
                >
                  <Box>
                    <Telephone
                      style={{
                        width: "30px",
                        height: "30px",
                        transform: "rotate(250deg)",
                      }}
                    />
                  </Box>
                </Link>
              </Box>
            )}
            {isDevice && (
              <Box width="8rem">
                <Button
                  variant="text"
                  sx={{ color: "#F3950D" }}
                  endIcon={<ArrowOutwardIcon />}
                  onClick={() => {
                    navigate("/get-a-quote");
                    window.scrollTo(0, 0);
                  }}
                >
                  <Typography variant="overline">Get A Quote</Typography>
                </Button>
              </Box>
            )}
          </Box>
        </AppBar>
      </Box>
      <Box height="5.863rem" maxHeight="5.863rem" />
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          <Fab
            size="small"
            aria-label="scroll back to top"
            variant="circular"
            sx={{ backgroundColor: "#F3950D !important" }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Fade>
    </>
  );
};

export default NavBar;
