import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
  Drawer,
  Collapse,
  Link,
} from "@mui/material";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
// import { ReactComponent as Telephone } from "../assets/telephone.svg";
import styled from "@emotion/styled";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logo from "../assets/fullcolor.svg";

interface SideDrawerProps {
  setToggleDrawer: Function;
  toggleDrawer: any;
}

const StyledButton = styled.button({
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
});

const StyledDivider = styled(Divider)({
  backgroundColor: "#62636C",
  textAlign: "center",
});

const SideDrawer = ({ setToggleDrawer, toggleDrawer }: SideDrawerProps) => {
  const [productDropDown, setProductDropDown] = useState(false);
  const [companyDropDown, setCompanyDropDown] = useState(false);

  const navigate = useNavigate();

  return (
    <Drawer
      anchor="right"
      open={toggleDrawer}
      onClose={() => setToggleDrawer(false)}
      sx={{
        padding: "0",
      }}
    >
      <Box
        bgcolor={"#393232"}
        width="100vw"
        height="100vh"
        sx={{ overflowX: "hidden" }}
      >
        <Box
          padding="0.25rem"
          display="flex"
          justifyContent={"space-between"}
          width={"100%"}
          zIndex={10}
        >
          <Box display="flex" gap="0.2rem" alignItems={"center"}>
            <Box m="0 auto">
              <StyledButton onClick={() => setToggleDrawer(false)}>
                <ArrowBackIcon style={{ color: "white" }} fontSize="large" />
              </StyledButton>
            </Box>
            <Link
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
                setToggleDrawer(false);
              }}
            >
              <Typography
                variant="body1"
                color="white"
                fontWeight="700"
                display="inline-block"
                fontFamily={"Fraunces"}
              >
                SAN BRUNO GROUP
              </Typography>
            </Link>
          </Box>
        </Box>
        <Box role="presentation" width={"100%"} color={"white"}>
          <List>
            <ListItemButton
              onClick={() => setProductDropDown(!productDropDown)}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    color={productDropDown ? "#F3950D" : "white"}
                  >
                    Products
                  </Typography>
                }
              />
              {productDropDown ? (
                <KeyboardArrowUpIcon sx={{ color: "#F3950D" }} />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </ListItemButton>
            <Collapse in={productDropDown} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate("/products/window");
                    setToggleDrawer(false);
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="white">
                        Windows
                      </Typography>
                    }
                  />
                </ListItemButton>
                <StyledDivider variant="middle" sx={{ width: "80%" }} />

                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate("/products/door");
                    setToggleDrawer(false);
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="white">
                        Doors
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            </Collapse>
            {!productDropDown && <StyledDivider variant="middle" />}
            {productDropDown && (
              <StyledDivider variant="middle" sx={{ width: "80%" }} />
            )}

            <ListItemButton
              onClick={() => {
                navigate("/services");
                setToggleDrawer(false);
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="body1" color={"white"}>
                    Services
                  </Typography>
                }
              />
            </ListItemButton>
            <StyledDivider variant="middle" />

            <ListItemButton
              onClick={() => {
                navigate("/warranty");
                setToggleDrawer(false);
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="body1" color="white">
                    Warranty
                  </Typography>
                }
              />
            </ListItemButton>
            <StyledDivider variant="middle" />

            <ListItemButton
              onClick={() => {
                navigate("/gallery");
                setToggleDrawer(false);
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="body1" color="white">
                    Gallery
                  </Typography>
                }
              />
            </ListItemButton>
            <StyledDivider variant="middle" />

            <ListItemButton
              onClick={() => setCompanyDropDown(!companyDropDown)}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    color={companyDropDown ? "#F3950D" : "white"}
                  >
                    Company
                  </Typography>
                }
              />
              {companyDropDown ? (
                <KeyboardArrowUpIcon sx={{ color: "#F3950D" }} />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </ListItemButton>
            <Collapse in={companyDropDown} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate("/about");
                    setToggleDrawer(false);
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="white">
                        About Us
                      </Typography>
                    }
                  />
                </ListItemButton>
                <StyledDivider variant="middle" sx={{ width: "80%" }} />

                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate("/our-process");
                    setToggleDrawer(false);
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="white">
                        Our Process
                      </Typography>
                    }
                  />
                </ListItemButton>
                <StyledDivider variant="middle" sx={{ width: "80%" }} />

                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate("/faqs");
                    setToggleDrawer(false);
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="white">
                        FAQ
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            </Collapse>
            {!companyDropDown && <StyledDivider variant="middle" />}
            {companyDropDown && (
              <StyledDivider variant="middle" sx={{ width: "80%" }} />
            )}

            <ListItemButton
              onClick={() => {
                navigate("/blogs");
                setToggleDrawer(false);
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="body1" color="white">
                    Blog
                  </Typography>
                }
              />
            </ListItemButton>
            <StyledDivider variant="middle" />
          </List>
          <Box
            textAlign="left"
            display={"flex"}
            flexDirection={"column"}
            justifyContent="left"
            gap="0.5rem"
            width="20rem"
            mt="2rem"
            p="0 1rem"
          >
            <Typography variant="subtitle1" color="white" fontWeight="bold">
              Contact Us
            </Typography>
            <Link sx={{ textDecoration: "none" }}>
              <Typography
                color="white"
                variant="body1"
                fontWeight="regular"
                textAlign={"left"}
              >
                <Link
                  href="tel:6132967866"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <strong>Phone:</strong> (613)-296-7866
                </Link>
              </Typography>
            </Link>
            <Typography color="white" variant="body1" fontWeight="regular">
              <Link
                href="mailto:Sales@sanbrunogroup.ca"
                style={{ textDecoration: "none", color: "white" }}
              >
                <strong>Sales:</strong> Sales@sanbrunogroup.ca
              </Link>
            </Typography>
            <Typography color="white" variant="body1" fontWeight="regular">
              <Link
                href="mailto:Service@sanbrunogroup.ca"
                style={{ textDecoration: "none", color: "white" }}
              >
                <strong>Service:</strong> Service@sanbrunogroup.ca
              </Link>
            </Typography>
            <Typography color="white" variant="body1" fontWeight="regular">
              <Link
                href="mailto:Info@sanbrunogroup.ca"
                style={{ textDecoration: "none", color: "white" }}
              >
                <strong>Info:</strong> Info@sanbrunogroup.ca
              </Link>
            </Typography>
            <Link sx={{ textDecoration: "none" }}>
              <Typography color="white" variant="body1" fontWeight="regular">
                44b Grenfell Cres. <br />
                Nepean, Ontario, Canada
              </Typography>
            </Link>
          </Box>
          <Box width="200px" m="0 0 0 auto">
            <img src={logo} alt="" />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideDrawer;
