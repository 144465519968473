import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import styled from "@emotion/styled";
import { useProduct } from "../hooks/use-product/useProduct";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import ContactUs from "./ContactUs";

// const StyledPillBox = styled(Box)({
//   border: "1px solid #1E1F24",
//   borderRadius: "12px",
// });

// const StyledPillContainer = styled(Box)({
//   display: "flex",
//   flexWrap: "nowrap",
//   overflowX: "scroll",
//   gap: "0.5rem",
//   //   border: "1px solid #E79C4A",
//   padding: "0.25rem",
//   //   backgroundColor: "#FFE3C3",
// });

const StyledButton = styled(Button)({
  "&:hover": {
    color: "#F3950D",
    borderColor: "#000000",
  },
});

const Products = () => {
  const { isDevice } = useScreenSizes();
  const { productData } = useProduct();
  const { category } = useParams();

  const filteredProducts = useMemo(() => {
    if (category) {
      return productData.filter((product) => product.type === category);
    } else {
      return productData;
    }
  }, [category, productData]);

  // const [windowsActive, setWindowsActive] = useState(false);

  // const [doorsActive, setDoorsActive] = useState(false);

  // const [functionActive, setFunctionActive] = useState(false);

  // const [fabricationActive, setFabricationActive] = useState(false);

  // const [showResetButton, setShowResetButton] = useState(false);

  // Logic to handle individual filter button click
  // const handleWindowsClick = () => {
  //   setWindowsActive(!windowsActive); // Toggle active state
  // };

  // const handleDoorsClick = () => {
  //   setDoorsActive(!doorsActive);
  // };

  // const handleTypeClick = () => {
  //   setFunctionActive(!functionActive);
  // };

  // const handleFabricationClick = () => {
  //   setFabricationActive(!fabricationActive);
  // };

  // // Logic to handle reset button click
  // const handleResetClick = () => {
  //   // Reset all filter button states
  //   setWindowsActive(false);
  //   setDoorsActive(false);
  //   setFunctionActive(false);
  //   setFabricationActive(false);
  //   setShowResetButton(false); // Hide reset button after reset
  // };

  // useEffect(() => {
  //   // Function to update reset button visibility
  //   const updateResetButtonVisibility = () => {
  //     // Check if any filter button is active
  //     if (windowsActive || doorsActive || functionActive || fabricationActive) {
  //       setShowResetButton(true); // Show reset button
  //     } else {
  //       setShowResetButton(false); // Hide reset button
  //     }
  //   };
  //   updateResetButtonVisibility();
  // }, [windowsActive, doorsActive, functionActive, fabricationActive]);

  const handleButtonClick = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        width={"100%"}
        sx={{ overflowX: "hidden" }}
        mt="1rem"
      >
        <Box
          padding={isDevice ? "0 1rem" : "0"}
          display="flex"
          flexDirection="column"
          gap={isDevice ? "0.5rem" : "0.25rem"}
          textAlign={isDevice ? "left" : "center"}
        >
          <Typography
            variant={isDevice ? "h4" : "h1"}
            color="#111111"
            fontWeight="bold"
          >
            Our Products
          </Typography>
          <Typography
            variant="subtitle2"
            color="#AA6800"
            mb="0.5rem"
            fontWeight="bold"
          >
            Discover the perfect Window and Door solutions tailored to your home
            by exploring our range of options.
          </Typography>
          <Typography variant="body1" color="#1E1F24">
            We're excited to showcase a range of exceptional products from
            leading brands such as Elite, Decko, and Novatech.
          </Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={isDevice ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
          width="100%"
          sx={{
            scrollBehavior: "smooth",
          }}
        >
          {filteredProducts.map((product) => (
            <Box
              key={product.id}
              width="100%"
              borderBottom={"0.5px solid #1E1F24"}
              borderRight={"0.5px solid #1E1F24"}
              borderLeft={"0.5px solid #1E1F24"}
              borderTop={"0.5px solid #1E1F24"}
            >
              <Box
                display={"grid"}
                gridTemplateColumns="150px 1fr"
                justifyContent={"center"}
                alignItems={"top"}
              >
                <Box width="150px" m={isDevice ? "" : "1rem 0"}>
                  <img src={product.img} alt="" width="150px" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="0.5rem"
                  p="0 0.5rem"
                  textAlign={"left"}
                  justifyContent={"top"}
                  mt="1rem"
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {product.function}
                  </Typography>
                  {product.fabrication === "Horizon" && (
                    <Typography
                      variant="overline"
                      fontWeight="bold"
                      color="#1E1F24"
                      mt="0.5rem"
                    >
                      {product.fabrication}, made of PVC.
                    </Typography>
                  )}
                  {product.fabrication === "Hybrid" && (
                    <Typography
                      variant="overline"
                      fontWeight="bold"
                      color="#1E1F24"
                      mt="0.5rem"
                    >
                      {product.fabrication}, made of PVC and aluminum blending.
                    </Typography>
                  )}
                  <Typography variant="body2">
                    {product.briefDescription}
                  </Typography>
                  {product.type === "window" &&
                    product.fabrication === "Horizon" && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="0.5rem"
                        alignItems={"left"}
                        mb="1rem"
                      >
                        {product.numberOfLinks === 3 && (
                          <Box
                            display="flex"
                            flexDirection={"column"}
                            gap="0.5rem"
                            width="15rem"
                          >
                            <StyledButton
                              variant="outlined"
                              endIcon={<ArrowOutwardIcon />}
                              sx={{
                                color: "#1E1F24",
                                border: "1px solid #1E1F24",
                              }}
                              onClick={() =>
                                handleButtonClick(product.links[0])
                              }
                              fullWidth
                            >
                              <Typography variant="button">Modular</Typography>
                            </StyledButton>
                            <StyledButton
                              variant="outlined"
                              endIcon={<ArrowOutwardIcon />}
                              sx={{
                                color: "#1E1F24",
                                border: "1px solid #1E1F24",
                              }}
                              onClick={() =>
                                handleButtonClick(product.links[1])
                              }
                              fullWidth
                            >
                              <Typography variant="button">
                                Structural
                              </Typography>
                            </StyledButton>
                            <StyledButton
                              variant="outlined"
                              endIcon={<ArrowOutwardIcon />}
                              sx={{
                                color: "#1E1F24",
                                border: "1px solid #1E1F24",
                              }}
                              onClick={() =>
                                handleButtonClick(product.links[2])
                              }
                              fullWidth
                            >
                              <Typography variant="button">
                                Integrated BrickMould
                              </Typography>
                            </StyledButton>
                          </Box>
                        )}
                        {product.numberOfLinks === 2 && (
                          <Box
                            display="flex"
                            flexDirection={"column"}
                            gap="0.5rem"
                            width={isDevice ? "100%" : "15rem"}
                          >
                            <StyledButton
                              variant="outlined"
                              endIcon={<ArrowOutwardIcon />}
                              sx={{
                                color: "#1E1F24",
                                border: "1px solid #1E1F24",
                              }}
                              onClick={() =>
                                handleButtonClick(product.links[0])
                              }
                              fullWidth
                            >
                              <Typography variant="button">Single</Typography>
                            </StyledButton>
                            <StyledButton
                              variant="outlined"
                              endIcon={<ArrowOutwardIcon />}
                              sx={{
                                color: "#1E1F24",
                                border: "1px solid #1E1F24",
                              }}
                              onClick={() =>
                                handleButtonClick(product.links[1])
                              }
                              fullWidth
                            >
                              <Typography variant="button">Double</Typography>
                            </StyledButton>
                          </Box>
                        )}
                      </Box>
                    )}
                  {product.type === "window" &&
                    product.fabrication === "Hybrid" && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="0.5rem"
                        alignItems={"left"}
                        mb="1rem"
                      >
                        {product.numberOfLinks === 1 && (
                          <Box width="15rem">
                            <StyledButton
                              variant="outlined"
                              endIcon={<ArrowOutwardIcon />}
                              sx={{
                                color: "#1E1F24",
                                border: "1px solid #1E1F24",
                              }}
                              onClick={() =>
                                handleButtonClick(product.links[0])
                              }
                              fullWidth
                            >
                              <Typography variant="button">
                                Structural
                              </Typography>
                            </StyledButton>
                          </Box>
                        )}
                        {product.numberOfLinks === 2 && (
                          <Box
                            display="flex"
                            flexDirection={"column"}
                            gap="0.5rem"
                            width={isDevice ? "100%" : "15rem"}
                          >
                            <StyledButton
                              variant="outlined"
                              endIcon={<ArrowOutwardIcon />}
                              sx={{
                                color: "#1E1F24",
                                border: "1px solid #1E1F24",
                              }}
                              onClick={() =>
                                handleButtonClick(product.links[0])
                              }
                              fullWidth
                            >
                              <Typography variant="button">Single</Typography>
                            </StyledButton>
                            <StyledButton
                              variant="outlined"
                              endIcon={<ArrowOutwardIcon />}
                              sx={{
                                color: "#1E1F24",
                                border: "1px solid #1E1F24",
                              }}
                              onClick={() =>
                                handleButtonClick(product.links[1])
                              }
                              fullWidth
                            >
                              <Typography variant="button">Double</Typography>
                            </StyledButton>
                          </Box>
                        )}
                      </Box>
                    )}
                  {product.type === "door" && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="0.5rem"
                      alignItems={"left"}
                      mb="1rem"
                    >
                      {product.numberOfLinks === 1 && (
                        <Box width="15rem">
                          <StyledButton
                            variant="outlined"
                            endIcon={<ArrowOutwardIcon />}
                            sx={{
                              color: "#1E1F24",
                              border: "1px solid #1E1F24",
                            }}
                            onClick={() => handleButtonClick(product.links[0])}
                            fullWidth
                          >
                            <Typography variant="button">
                              Explore More
                            </Typography>
                          </StyledButton>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box>
        <ContactUs />
      </Box>
    </>
  );
};

export default Products;
