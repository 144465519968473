import React from "react";
import { Box, Typography, Card, Button, Divider } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SellIcon from "@mui/icons-material/Sell";
import BuildIcon from "@mui/icons-material/Build";
import line6 from "../assets/line-6.svg";
import line6Web from "../assets/line-6-web.svg";
import styled from "@emotion/styled";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import home1 from "../assets/hn-build.jpg";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import AirIcon from "@mui/icons-material/Air";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)({
  backgroundColor: "#FCFCFD",
  padding: "0 0.75rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  justifyContent: "center",
  alignItems: "center",

  border: "1px solid #CDCED7",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "12px",
});

const StyledProjectButton = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  borderRadius: "30px",
  padding: "0.5rem 1rem",
});

const ServiceSection = () => {
  const { isDevice } = useScreenSizes();
  const navigate = useNavigate();

  const services = [
    {
      id: 0,
      title: "Full Sales and Installation Team",
      icon: (
        <SellIcon
          sx={{
            height: "4.5rem",
            width: "5rem",
            color: "#4E351A",
            rotate: "85deg",
          }}
        />
      ),
    },
    {
      id: 1,
      title: "Sales Only - Curbside pick up ",
      icon: (
        <LocalShippingIcon
          sx={{ height: "5rem", width: "5rem", color: "#4E351A" }}
        />
      ),
    },
    {
      id: 2,
      title: "Installation Only (for Re-sellers, Builders and Contractors)",
      icon: (
        <BuildIcon
          sx={{
            height: "4rem",
            width: "5rem",
            color: "#4E351A",
            rotate: "85deg",
          }}
        />
      ),
    },
    {
      id: 3,
      title: "Consulting and Troubleshooting Support for Complex Issues",
      icon: (
        <EngineeringIcon
          sx={{ height: "5rem", width: "5rem", color: "#4E351A" }}
        />
      ),
    },
  ];

  const handleClick = () => {
    const anchor = document.querySelector("#project-section");

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth", // Add smooth scrolling behavior
        block: "start", // Scroll to the top of the viewport
      });
    }
  };

  return (
    <Box p={isDevice ? "0 1rem" : "0 6rem"} mt="3rem" textAlign={"center"}>
      {isDevice && (
        <Box mb="1rem" textAlign={"left"}>
          <Typography
            variant={"subtitle1"}
            color="#AA6800"
            mb="0.5rem"
            fontWeight="bold"
          >
            What We Do
          </Typography>
          <Box
            display="flex"
            justifyContent={"left"}
            alignItems={"center"}
            gap="1rem"
          >
            <Typography variant={"h3"} color="#1E1F24">
              Our Services
            </Typography>
            <Box>
              <img src={line6} alt="" />
            </Box>
          </Box>
        </Box>
      )}
      {!isDevice && (
        <Box mb="1rem" textAlign={"left"}>
          <Typography
            variant={isDevice ? "subtitle2" : "subtitle1"}
            color="#AA6800"
            mb="0.5rem"
            fontWeight="bold"
          >
            What We Do
          </Typography>
          <Box display="flex" gap="1rem" alignItems={isDevice ? "" : "center"}>
            <Typography variant={"h4"} color="#1E1F24">
              Our Services
            </Typography>
            <Box>
              <img src={isDevice ? line6 : line6Web} height={12} alt="" />
            </Box>
          </Box>
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
        textAlign={"left"}
      >
        <Typography variant={isDevice ? "body1" : "subtitle2"} color="#1E1F24">
          We believe your San Bruno experience is about more than just windows
          and doors. It’s about caring for you and your home, every step of the
          way.
        </Typography>
        <Typography variant={isDevice ? "body1" : "subtitle2"} color="#1E1F24">
          We oversee every step of the process, making sure you are always
          informed and 'in the loop'. This means we listen attentively to your
          needs,
          {!isDevice && <br />}offer tailored solutions, and expertly execute
          installations. We stand behind our products and our work.
        </Typography>
        <Typography
          variant={isDevice ? "body1" : "subtitle2"}
          color="#1E1F24"
          textAlign={"left"}
        >
          We're focused on commercial and residential sales, Installation and
          Service. We provide our clients with the highest level of quality and
          service {!isDevice && <br />} for both in and out of warranty Elite
          Window products.
        </Typography>
      </Box>
      <Box sx={{ overflowY: "auto" }}>
        <Box
          display="flex"
          gap={isDevice ? "1rem" : "3rem"}
          alignItems={"center"}
          textAlign={"center"}
          p={"1rem 1rem 1rem 0"}
          width="100vw"
        >
          <Box textAlign={"left"}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
                width: isDevice ? "200px" : "300px",
                height: isDevice ? "15rem" : "20rem",
                backgroundColor: "#F3950D",
                padding: "0 0.75rem",
              }}
            >
              <Typography variant="subtitle2" color="white" fontWeight={600}>
                Empower yourself with options, but let us handle the heavy
                lifting.
              </Typography>
              <Box m="0 auto 0 0">
                <Button
                  sx={{
                    color: "#1E1F24",
                    border: "1px solid #F3950D",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#000000",
                    },
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "30px",
                  }}
                  variant="contained"
                  onClick={() => {
                    navigate(`/services`);
                    window.scrollTo(0, 0);
                  }}
                >
                  <ArrowOutwardIcon sx={{ color: "#F3950D" }} />
                </Button>
              </Box>
            </Card>
          </Box>
          {services.map((service) => (
            <Box key={service.id}>
              <StyledCard
                sx={{
                  width: isDevice ? "200px" : "300px",
                  height: isDevice ? "15rem" : "20rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/services`);
                  window.scrollTo(0, 0);
                }}
              >
                <Box m="0 auto">{service.icon}</Box>
                <Typography variant="body1" color="#4E351A" fontWeight="bold">
                  {service.title}
                </Typography>
              </StyledCard>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        display={"flex"}
        flexDirection={isDevice ? "column" : "row"}
        justifyContent={isDevice ? "center" : "space-between"}
      >
        <Box
          display={"grid"}
          gridTemplateColumns={isDevice ? "1fr" : "40rem"}
          textAlign="left"
          mt="1rem"
          gap={isDevice ? "1rem" : "5rem"}
        >
          <Box>
            <img
              src={home1}
              alt=""
              style={{ width: "100%", borderRadius: "12px" }}
            />
          </Box>
          <Box>
            <Typography variant={"h4"} color="#1E1F24" mb="1rem">
              Life and Weatherproof
            </Typography>
            <Typography
              variant={isDevice ? "body1" : "subtitle2"}
              lineHeight={1.5}
            >
              San Bruno Group profiles are known for lasting for decades, our
              hybrid line is aluminium clad, and withstanding the harshest
              summer and winter climates.
            </Typography>
            <Typography
              variant={isDevice ? "body1" : "subtitle2"}
              mt="1.5rem"
              lineHeight={1.5}
            >
              Within our San Bruno Group showroom, we have on display the
              windows and doors you have been looking for.. Every day, we
              install and service custom door and window solutions to complete
              the aesthetic style of projects without compromising on longevity,
              performance or strength.
            </Typography>
            <Box
              display={"flex"}
              m="1.45rem auto"
              width={isDevice ? "100%" : "60%"}
              justifyContent={isDevice ? "space-evenly" : "space-evenly"}
              alignItems={isDevice ? "center" : ""}
              gap={isDevice ? "" : "3rem"}
            >
              <Box textAlign={"center"}>
                <WbSunnyIcon
                  sx={{ color: "#4E351A", width: "3rem", height: "3rem" }}
                />
                <Typography>Sun</Typography>
              </Box>
              <Box textAlign={"center"}>
                <AirIcon
                  sx={{ color: "#4E351A", width: "3rem", height: "3rem" }}
                />
                <Typography>Wind</Typography>
              </Box>
              <Box textAlign={"center"}>
                <WaterDropIcon
                  sx={{ color: "#4E351A", width: "3rem", height: "3rem" }}
                />
                <Typography>Rain</Typography>
              </Box>
              <Box textAlign={"center"}>
                <AcUnitIcon
                  sx={{ color: "#4E351A", width: "3rem", height: "3rem" }}
                />
                <Typography>Snow</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          textAlign={"center"}
          display={"flex"}
          flexDirection={"column"}
          gap={isDevice ? "1rem" : "2rem"}
          m={isDevice ? "1.5rem auto 0 auto" : "1.5rem auto"}
          bgcolor={"#f0d7b8"}
          p={isDevice ? "1rem" : "1rem 2rem"}
          borderRadius={"12px"}
          height={isDevice ? "100%" : ""}
        >
          <Typography variant={isDevice ? "h5" : "h3"} fontWeight="regular">
            Explore Our Range
          </Typography>
          <Typography variant={isDevice ? "body1" : "subtitle2"}>
            <strong style={{ color: "#AA6800" }}>Horizon PVC Windows:</strong>{" "}
            Contemporary, practical, and durable windows {!isDevice && <br />}{" "}
            with easy maintenance, superior seal, and performance.
          </Typography>
          <Typography variant={isDevice ? "body1" : "subtitle2"}>
            <strong style={{ color: "#AA6800" }}>
              Hybrid PVC and Aluminum Windows:
            </strong>{" "}
            Blending PVC and aluminum for {!isDevice && <br />}
            remarkable durability, multiple arrangement options, and{" "}
            {!isDevice && <br />} a wide selection of colors.
          </Typography>

          <Typography variant={isDevice ? "body1" : "subtitle2"}>
            <strong style={{ color: "#AA6800" }}>Doors:</strong> Solid and
            practical steel or fibreglass doors with {!isDevice && <br />}{" "}
            various finishes, sizes, and glazing options to{" "}
            {!isDevice && <br />}suit all building types and tastes.
          </Typography>
          <Box>
            <Box width="20rem" m={isDevice ? "0rem auto 1rem auto" : "0 auto"}>
              <Divider sx={{ backgroundColor: "#AA6800", height: "0.5px" }} />
            </Box>
            <Box
              width={isDevice ? "100%" : "50%"}
              m={isDevice ? "0 auto" : "1rem auto"}
            >
              <StyledProjectButton onClick={() => handleClick()} fullWidth>
                <Typography variant="button">
                  Tell us about your project
                </Typography>
              </StyledProjectButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceSection;
