const useFaq = () => {
  const faqData = [
    {
      id: 1,
      title: "What’s next after finalizing my window order?",
      content:
        "Once you place your order, you will be given an approximate date for delivery. We will then contact you to confirm the exact arrival day, and if you have chosen our team for the installation, we will discuss when that will begin just prior to the delivery.",
    },
    {
      id: 2,
      title: "What are my responsibilities at the time of delivery?",
      content:
        "You need to make sure there is good access for the truck on the street and on your property. The ground must be level, clear of debris and slip/tripping hazards. Windows are typically left either in the garage or on the main floor. The driver and helpers do not carry any products up stairs. If you have chosen our team for the installation, we take care of placing the products on the appropriate floor. You must inspect all your windows and doors and report any damage that may have occurred during the delivery. Take pictures right away and send them to us.",
    },
    {
      id: 3,
      title: "How and When do I pay for my windows?",
      content:
        "A deposit of 50% is due at the time of ordering. The remaining 50% is payable at the time of delivery. We accept electronic funds transfers, cheques, certified cheques, and cash. If you wish to pay by credit card, we may be able to arrange that, but extra charges will apply due to processing fees.",
    },
    {
      id: 4,
      title:
        "How do I coordinate site assembly or big loose glass installation?",
      content:
        "Call our office a week prior to your receiving the windows, so we can schedule technicians on delivery day to assemble modules for your framer. If you have big windows with big glass, the glass typically comes loose, and is not delivered in the frames. Once the window frames are installed and leveled, we send technicians to install the glass in the frames. Contact our office to schedule the site install. Get an estimated date from your framer, so we can get you in the Service Calendar. If we are installing your windows, we will take care of the glass installation too.",
    },
    {
      id: 5,
      title:
        "What should I do if a window or door was damaged during construction?",
      content:
        "Contact us immediately so we can assess the damage and see if it is repairable or if new parts/products need to be ordered.",
    },
    {
      id: 6,
      title: "What is a VIP Service?",
      content:
        "A VIP service comes near the end of your construction project. After your final deep clean, our service technicians complete the following: <br/><br/> ●Test the function of every window and exterior door in your home <br/><br/> ●Install the cranks <br/><br/> ●Install the screens <br/><br/> ●Remove protection from your doors and inspect <br/><br/> ●Make a full report of any issues and fix what they can. They send their report to us. <br/><br/> ●We order the necessary materials and then contact you for a Return Service",
    },
    {
      id: 7,
      title: "How much does a VIP cost?",
      content:
        "The cost for either service depends on the number of doors and windows. It is different for every project.",
    },
    {
      id: 8,
      title: "Is a VIP worth the extra cost?",
      content:
        "If you want any issues identified and either repaired or parts ordered for repair prior to you or the homeowner taking possession, then yes. If you want to protect your expensive doors and sills with a layer of factory applied protection, then yes. If you want your screens to survive the construction process and be installed only after the house is cleaned by your contractor, or our contractor in the case of a Marvin PPS, then yes. Remember, damage to your products that occur during or after construction are not covered under warranty.",
    },
    {
      id: 9,
      title: "When should I schedule my VIP?",
      content:
        "Contact us 3 weeks before you want your VIP (Elite). For Elite, we will order the materials to be released from the factory to be shipped to us here in Ottawa. We will get you in the Service Calendar at the same time. For Marvin, we will schedule the PPS along with your professional window cleaning. Ideally, the VIP service is best done after a deep clean of your home, including the windows. Tip: make sure your cleaners open the windows and clean all the construction debris and dust from the mechanisms (it will ensure your windows function smoothly and that the hardware will last).",
    },
    {
      id: 10,
      title:
        "What are some things to consider when ordering windows for a new home?",
      content:
        "Maintenance and upkeep: We can point you in the direction of what window type is good for you. Schedule an appointment at our showroom and we will show you and explain the difference between the various options, taking your budget into consideration. Access for after sales service: Sometimes we see limits pushed with big glass--to bring the outside in. Before signing off, consider how a technician might replace the glass, should the seal fail down the road. When we assess a site for access, depending on the size and weight of the glass, we look at stairs, bulkheads, tight corners, high placement in great rooms, stairwells, etc—all things that will impact a safe entry and exit. For Elite’s former line--wood Tradition Window, exterior access is also a consideration. Outside, we look at height, stability of ground, whether scaffolding, ladders, or lifts are required.",
    },
    {
      id: 11,
      title: "What is the warranty on my new windows?",
      content:
        "See <a href='http://www.fenetreselite.com/en/warranties/' target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}}>Elite</a> for complete manufacturer information.",
    },
    {
      id: 12,
      title: "What is NOT covered under warranty?",
      content: `
      ● Normal wear and tear to the hardware caused by faulty manipulation. <br/><br/> 
      ● Damage caused by chemical products (solvent and abrasive products). <br/><br/> 
      ● Poor efficiency caused by negligent or inadequate usage; damage caused by the absence of maintenance, modifications or alterations done to the window. <br/><br/> 
      ● All glass breakage. <br/><br/> ● Incorrect or nonstandard installation, malfunctioning problems and problems connected to infiltrations, air and water leaks arising out of the poor or bad installation or relative to problems relating to the conception and or construction of the building. <br/><br/> 
      ● Damage caused to the screen by normal usage, abusive or inappropriate usage, or done by an animal. <br/><br/> 
      ● Condensation or damages caused by condensation. <strong>Note:</strong> Unless the damage is
        relative to a breakage or defect of the insulated glass, most of the problems relating to
        the condensation are linked to the high, excessive humidity levels in the buildings.`,
    },
    {
      id: 13,
      title: "It’s winter, cold or raining: Can I still get my glass replaced?",
      content: `Yes, we replace glass all year round. Contact us to discuss the nature of your project and we
      will give you an idea of how long the service will take. Then you can decide when you prefer
      to have the work done.`,
    },
  ];

  return { faqData };
};

export default useFaq;
