import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";

const StyledCard = styled(Card)({
  backgroundColor: "#FCFCFD",
  padding: "0 0.75rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  justifyContent: "center",
  alignItems: "center",
  width: "250px",
  height: "350px",
  border: "1px solid #CDCED7",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "12px",
});

const StyledProjectButton = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  borderRadius: "30px",
  padding: "0.5rem 1rem",
});

const OurProcess = () => {
  const navigate = useNavigate();
  const { isDevice } = useScreenSizes();

  const processSteps = [
    {
      id: 1,
      step: "Initial Contact",
      description:
        "The process begins with you reaching out to us, whether through our website, phone, or in person.",
    },
    {
      id: 2,
      step: "Determine Project Needs and Budget",
      description:
        "We'll discuss your project requirements, preferences, and budget to understand your vision and goals.",
    },
    {
      id: 3,
      step: "Schedule a Showroom Visit",
      description:
        "Visit our showroom to explore our wide range of products and discuss options with our experts.",
    },
    {
      id: 4,
      step: "Select Product",
      description:
        "Choose the perfect windows, doors, or other products that suit your style and requirements.",
    },
    {
      id: 5,
      step: "Review Drawings and Send Client Questions",
      description:
        "Once selections are made, we'll review drawings and send any necessary follow-up questions for clarification.",
    },
    {
      id: 6,
      step: "Site Visit (if appropriate)",
      description:
        "Depending on the project scope, we may conduct a site visit to ensure accurate measurements and assessments.",
    },
    {
      id: 7,
      step: "Discuss Value Added Packages",
      description:
        "We'll discuss any value-added packages available, such as VIP for Elite products.",
    },
    {
      id: 8,
      step: "Review Pricing and Details",
      description:
        "Transparently review pricing and project details to ensure alignment with your expectations.",
    },
    {
      id: 9,
      step: "Prepare Order",
      description:
        "Once everything is finalized, we'll prepare your order for processing.",
    },
    {
      id: 10,
      step: "Review Order",
      description:
        "You'll have the opportunity to review the order details before finalizing.",
    },
    {
      id: 11,
      step: "Retain Deposit",
      description:
        "Upon agreement, a deposit will be retained to secure your order.",
    },
    {
      id: 12,
      step: "Place Order",
      description:
        "With the deposit secured, we'll proceed to place the order with our suppliers.",
    },
    {
      id: 13,
      step: "Delivery, Installation, and Service",
      description:
        "Finally, we'll coordinate delivery and installation, ensuring a smooth process from start to finish. If applicable, our VIP service will be provided at the end of the project to ensure your complete satisfaction.",
    },
  ];

  return (
    <Box
      p="1rem"
      display="flex"
      flexDirection={"column"}
      gap="1rem"
      mb={"2rem"}
    >
      <Typography variant={isDevice ? "h5" : "h3"} fontWeight={"bold"}>
        Our Process
      </Typography>
      <Typography variant={isDevice ? "body1" : "subtitle2"}>
        At San Bruno Group, we strive to make the experience of working with us
        as seamless and enjoyable as possible. <br />
        <br /> <strong>Here's a step-by-step guide to our process:</strong>
      </Typography>
      <Box sx={{ overflowY: "auto" }}>
        <Box
          display="flex"
          gap="1rem"
          alignItems={"center"}
          width="100vw"
          padding={"1rem 0"}
        >
          {processSteps.map((step) => (
            <Box key={step.id}>
              <StyledCard>
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Typography variant="h6">{step.id}.</Typography>
                  <Typography variant="subtitle2" fontWeight="bold">
                    {step.step}
                  </Typography>
                  <Typography variant="body1">{step.description}</Typography>
                </CardContent>
              </StyledCard>
            </Box>
          ))}
        </Box>
      </Box>
      <Box m="0 auto">
        <StyledProjectButton
          onClick={() => {
            navigate("/get-a-quote");
            window.scrollTo(0, 0);
          }}
        >
          <Typography variant="button">Tell us about your project</Typography>
        </StyledProjectButton>
      </Box>
    </Box>
  );
};

export default OurProcess;
