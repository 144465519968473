import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IntroAnimation from "./components/IntroAnimation";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import theme from "./hooks/use-theme/usetheme";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import About from "./components/About";
import Products from "./components/Products";
import Blogs from "./components/Blogs";
import FAQ from "./components/FAQ";
import FaqDetails from "./components/FaqDetails";
import OurProcess from "./components/OurProcess";
import Warranty from "./components/Warranty";
import Services from "./components/Services";
import Gallery from "./components/Gallery";
import GetAQuote from "./components/GetAQuote";

function App() {
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <>
          {showIntro ? (
            <IntroAnimation />
          ) : (
            <>
              <NavBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/products" element={<Products />} />

                <Route path="/products/:category" element={<Products />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/faqs" element={<FAQ />} />
                <Route path="/faq/:id" element={<FaqDetails />} />
                <Route path="/our-process" element={<OurProcess />} />
                <Route path="/warranty" element={<Warranty />} />
                <Route path="/services" element={<Services />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/get-a-quote" element={<GetAQuote />} />
              </Routes>
              <Footer />
            </>
          )}
        </>
      </Router>
    </ThemeProvider>
  );
}

export default App;
