import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box } from "@mui/material";
import img1 from "../assets/ManorPark-Hobin-05-PRINT.jpg";
import img2 from "../assets/section2-2.jpg";
import img3 from "../assets/section2-5.jpg";
import img4 from "../assets/section2-4.jpg";
import img5 from "../assets/269-273Carruthers-29-PRINT.jpg";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";

const images = [
  {
    id: 0,
    img: img1,
  },
  {
    id: 1,
    img: img2,
  },
  {
    id: 2,
    img: img3,
  },
  {
    id: 3,
    img: img4,
  },
  {
    id: 4,
    img: img5,
  },
];

const ImageSlider = () => {
  const timeRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const { isDevice } = useScreenSizes();

  const goToNext = useCallback(() => {
    if (currentSlideIndex === images.length - 1) {
      setCurrentSlideIndex(0);
    } else {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  }, [currentSlideIndex]);

  const goToSlide = (slideIndex: number) => {
    setCurrentSlideIndex(slideIndex);
  };

  useEffect(() => {
    // clear the previouse timer if it still exists
    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }
    // set a new timer using setTimeout
    //@ts-ignore
    timeRef.current = setTimeout(() => {
      goToNext();
    }, 8000);

    // cleanup function: clear the timer when the component unmounts or when the dependency array changes
    //@ts-ignore
    return () => clearTimeout(timeRef.current);
  }, [goToNext]);

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns={isDevice ? "1fr" : "1fr"}
        gap="0.5rem"
      >
        <Box width={isDevice ? "100%" : "1200px"}>
          <img
            src={images[currentSlideIndex].img}
            alt=""
            style={{
              width: isDevice ? "100%" : "1200px",
              height: isDevice ? "290px" : "650px",
              borderRadius: isDevice ? "0" : "12px",
            }}
          />
        </Box>
        <Box
          color="white"
          display="flex"
          justifyContent="center"
          sx={{ cursor: "pointer" }}
          width="100%"
          height="100%"
        >
          {images.map((slide) => (
            <Box
              key={slide.id}
              margin="0 0.2rem"
              width={currentSlideIndex === slide.id ? "3rem" : "1rem"}
              height="0.3rem"
              bgcolor={
                currentSlideIndex === slide.id
                  ? "var(--secondary-night, #AA6800)"
                  : "var(--placeholder-night, #393232);"
              }
              onClick={() => {
                goToSlide(slide.id);
              }}
            ></Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ImageSlider;
