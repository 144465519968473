import React from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { Work, ShoppingBasket, Build, HelpOutline } from "@mui/icons-material";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import ContactUs from "./ContactUs";

const Services = () => {
  const { isDevice } = useScreenSizes();
  return (
    <>
      <Box p="1rem" mb={"2rem"}>
        <Typography variant="h4" align="center" gutterBottom>
          Our Services
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary">
          Providing comprehensive solutions for all your window and door needs
        </Typography>
        <Grid container spacing={3} justifyContent="center" mt={4}>
          {/* Full Sales and Installation Team */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                width: "370px",
                height: isDevice ? "90%" : "580px",

                boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                margin: "0 auto",
                borderRadius: "12px",
                padding: "1rem",
                backgroundColor: "#FFEFDD",
              }}
            >
              <CardHeader
                title="Full Sales and Installation Team"
                subheader="We provide end-to-end support for your window and door needs."
                titleTypographyProps={{
                  variant: "subtitle1",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#1E1F24",
                }}
                subheaderTypographyProps={{
                  variant: "body1",
                  textAlign: "center",
                  color: "#AA6800",

                  sx: {
                    opacity: "0.8",
                    marginTop: "0.5rem",
                  },
                }}
              />
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Box m={"0 auto"}>
                  <Work sx={{ fontSize: "3rem", color: "#1E1F24" }} />
                </Box>

                <Typography
                  variant="body1"
                  color={"#1E1F24"}
                  textAlign={"center"}
                  gutterBottom
                >
                  We walk through your project with you to ensure you are
                  getting everything you want, need, and expect. We explain
                  optional add-ons, like the VIP service, and answer all your
                  questions about future service and warranty.
                </Typography>
                <Box m={"0 auto"}>
                  <Build sx={{ fontSize: "3rem", color: "#1E1F24" }} />
                </Box>
                <Typography variant="body1" gutterBottom textAlign={"center"}>
                  We are expert window and door installers and stand behind that
                  claim. We know Elite Windows Doors products inside out and
                  always follow the engineering protocols for their
                  installation. Your windows and doors will be perfectly
                  installed, or we come back to correct that!
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Sales Only - Curbside pick up */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                width: "370px",
                height: isDevice ? "90%" : "580px",

                boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                margin: "0 auto",
                borderRadius: "12px",
                padding: "1rem",
                backgroundColor: "#FFEFDD",
              }}
            >
              <CardHeader
                title="Sales Only - Curbside pick up"
                subheader="For builders or homeowners who have all their installation needs in place already."
                titleTypographyProps={{
                  variant: "subtitle1",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                subheaderTypographyProps={{
                  variant: "body1",
                  textAlign: "center",
                  color: "#AA6800",
                  sx: {
                    opacity: "0.8",
                    marginTop: "0.5rem",
                  },
                }}
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "space-between",
                  height: isDevice ? "100%" : " 20rem",
                }}
              >
                <Box m={isDevice ? "0 auto" : "2rem auto 0 auto"}>
                  <ShoppingBasket sx={{ fontSize: "3rem", color: "#1E1F24" }} />
                </Box>
                <Box>
                  <Typography variant="body1" textAlign={"center"} gutterBottom>
                    We are happy to provide sales only, for pick up at our
                    showroom at 44 Grenfell. Note: products must be picked up
                    within 5 business days of delivery.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Installation Only */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                width: "370px",
                height: isDevice ? "90%" : "580px",

                boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                margin: "0 auto",
                borderRadius: "12px",
                padding: "1rem",
                backgroundColor: "#FFEFDD",
              }}
            >
              <CardHeader
                title="Installation Only (for Re-sellers, Builders and Contractors)"
                subheader="Access to our new showroom and a wide range of products."
                titleTypographyProps={{
                  variant: "subtitle1",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                subheaderTypographyProps={{
                  variant: "body1",
                  textAlign: "center",
                  color: "#AA6800",
                  sx: {
                    opacity: "0.8",
                    marginTop: "0.5rem",
                  },
                }}
              />
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Box m={"0 auto"}>
                  <Build sx={{ fontSize: "3rem", color: "#1E1F24" }} />
                </Box>
                <Typography variant="body1" textAlign={"center"} gutterBottom>
                  If you have purchased Elite Windows already (or plan to), by
                  choosing San Bruno Group for your installation needs, you will
                  have access to our new showroom to see our Hybrid and Horizon
                  window lines, over 22 samples of Novatech swing doors on
                  display, and try for yourself the smooth Loft lift and slide
                  door.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Consulting and Troubleshooting Support */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                width: "370px",
                height: isDevice ? "90%" : "580px",

                boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                margin: "0 auto",
                borderRadius: "12px",
                padding: "1rem",
                backgroundColor: "#FFEFDD",
              }}
            >
              <CardHeader
                title="Consulting and Troubleshooting Support for Complex Issues"
                titleTypographyProps={{
                  variant: "subtitle1",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  height: isDevice ? "100%" : " 20rem",
                }}
              >
                <Box m={isDevice ? "0 auto" : "3rem auto 0 auto"}>
                  <HelpOutline sx={{ fontSize: "3rem", color: "#1E1F24" }} />
                </Box>
                <Typography textAlign="center" variant="body1" gutterBottom>
                  We provide support for complex issues such as disabling a
                  product as a safety requirement by City inspectors, site
                  access challenges, and product questions.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ContactUs />
    </>
  );
};

export default Services;
