import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  TextField,
  Link,
  Snackbar,
  Slide,
} from "@mui/material";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import styled from "styled-components";
import validator from "validator";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
// import emailjs from "@emailjs/browser";

const StyledBorderTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: #aa6800;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #aa6800;
      }
    }
    & .MuiFormHelperText-root {
      color: red;
    }
  }
`;

const ContactUs = () => {
  const { isDevice } = useScreenSizes();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  //   const [templateId, setTemplateId] = useState("template_odum9mi");

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();

  //   // const serviceId = "service_bzd1lj6";
  //   // const publicKey = "53U0rQHrSrDIEmca-";

  //   // const templateParams = {
  //   //   from_name: name,
  //   //   from_email: email,
  //   //   phone_number: phoneNumber,
  //   //   guest_number: numberOfGuests,
  //   //   event_date: eventDate,
  //   //   time: eventTime,
  //   //   message: message,
  //   //   to_name: "Coco and Marlo",
  //   // };

  //   // emailjs
  //   //   .send(serviceId, templateId, templateParams, publicKey)
  //   //   .then((response) => {
  //   //     // Clear the form fields after submission
  //   //     setName("");
  //   //     setEmail("");
  //   //     setMessage("");
  //   //     setPhoneNumber("");
  //   //     setNumberOfGuests("");
  //   //     setEventDate("");
  //   //     setEventTime("");
  //   //     setPrivateEvent(false);
  //   //   })
  //   //   .catch((err) => {
  //   //     throw new Error("Failed", err);
  //   //   });
  // };

  const validateEmail = () => {
    if (validator.isEmail(email)) {
      return true;
    }
    return false;
  };
  const validatePhone = () => {
    if (validator.isMobilePhone(phoneNumber)) {
      return true;
    }
    return false;
  };

  const handleDisableSubmit = () => {
    if (!name || !email || !message) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box
      sx={{
        padding: isDevice ? "" : "2rem",
        overflowX: "hidden",
      }}
      bgcolor="#EFF0F3"
    >
      <Box mb="2rem">
        <Typography
          variant={isDevice ? "h3" : "h1"}
          textAlign={"center"}
          mb={isDevice ? "1rem" : "0"}
        >
          Contact Us
        </Typography>
        <Typography
          textAlign={"center"}
          variant="body1"
          ml={isDevice ? "-3rem" : ""}
        >
          Here are few ways to get in touch with us.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={isDevice ? "column" : "row"}
        justifyContent="space-evenly"
        gap="1rem"
        m={"1rem auto"}
        width="80%"
      >
        <Box
          textAlign={"left"}
          display="flex"
          flexDirection={"column"}
          gap="0.5rem"
        >
          <Link
            href="tel:6132967866"
            style={{ textDecoration: "none", color: "#1E1F24" }}
            sx={{ textDecoration: "none" }}
          >
            <Typography color="#1E1F24" variant="body1" fontWeight="regular">
              <strong>Phone:</strong> (613)-296-7866
            </Typography>
          </Link>
          <Link
            href="mailto:Info@sanbrunogroup.ca"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Typography color="#1E1F24" variant="body1" fontWeight="regular">
              <strong>Sales & Services:</strong> Info@sanbrunogroup.ca
            </Typography>
          </Link>
          <Link
            sx={{ textDecoration: "none" }}
            href="https://maps.app.goo.gl/xC5eyVNFECQi89LVA"
          >
            <Typography color="#1E1F24" variant="body1" fontWeight="regular">
              <strong>Location:</strong> 44b Grenfell Cres. Nepean, Ontario,
              Canada
            </Typography>
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={"center"}
          width={isDevice ? "100%" : "39.125rem"}
          gap="2rem"
        >
          <Box display="flex" flexDirection="column" gap="1rem">
            <Typography
              variant="subtitle1"
              mb="0.5rem"
              fontWeight="bold"
              mt={isDevice ? "1rem" : ""}
            >
              Message Us
            </Typography>
            <StyledBorderTextField
              variant="outlined"
              label="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
            />
            <StyledBorderTextField
              variant="outlined"
              label="Email"
              fullWidth
              value={email}
              helperText={
                validateEmail() || email?.length === 0 ? "" : "Invalid Email"
              }
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <StyledBorderTextField
              variant="outlined"
              label="Phone Number"
              fullWidth
              multiline
              // rows={10}
              value={phoneNumber}
              helperText={
                validatePhone() || phoneNumber?.length === 0
                  ? ""
                  : "Invalid Phone Number"
              }
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <StyledBorderTextField
              variant="outlined"
              label="Message"
              fullWidth
              multiline
              rows={5}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <Typography fontSize="14px" fontWeight="bold">
              For privacy and security purposes, please do not share your credit
              card or payment information in this field. {isDevice && <br />}
              Thank you.
            </Typography>
          </Box>

          <Box width="30%" margin="0 auto 2rem auto">
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: "3rem",
                borderRadius: "30px",
                backgroundColor: "#F3950D",
                padding: "1rem",
                "&:hover": {
                  backgroundColor: "#000000",
                },
              }}
              type="submit"
              onClick={() => {
                setOpenSnackbar(!openSnackbar);
              }}
              disabled={handleDisableSubmit()}
            >
              <Typography variant="h6">SEND</Typography>
            </Button>
          </Box>
          <Snackbar
            open={openSnackbar}
            anchorOrigin={{
              vertical: isDevice ? "bottom" : "top",
              horizontal: isDevice ? "left" : "right",
            }}
            message={
              <Box display="flex" flexDirection={"column"} gap="0.25rem">
                <CircleNotificationsIcon
                  sx={{ color: "#F3950D", fontSize: 30 }}
                />
                <Typography variant="overline">
                  {" "}
                  <span style={{ color: "#F3950D" }}>
                    Message sent successfully!
                  </span>{" "}
                  <br /> We will get back to you as soon as possible.
                </Typography>
              </Box>
            }
            onClose={() => setOpenSnackbar(false)}
            autoHideDuration={3000}
            TransitionComponent={(props) => (
              <Slide {...props} direction="left" />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUs;
