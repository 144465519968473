import React from "react";
import { Typography, Box, Card, CardMedia, CardContent } from "@mui/material";
import casementWindow from "../assets/window-casement-white.png";
import casementWindowInterior from "../assets/window-interior-cut-casement-white.jpeg";
import awningWindow from "../assets/window-awing-white.png";
import hungSingleWindow from "../assets/window-single-hung-white.png";
import sliderSingleWindow from "../assets/window-single-slider-white.png";
import sliderSingleWindowInterior from "../assets/window-interior-cut-single-slider-white.jpeg";
import novatechDoor1 from "../assets/novatech-door-black.webp";
import novatechPatioDoor1 from "../assets/novatech-patio-door-grey.webp";

const ProductDisplay = () => {
  const productData = [
    {
      id: 0,
      type: "window",
      function: "Casement",
      img: casementWindow,
      imgInterior: casementWindowInterior,
      briefDescription:
        "Versatile casement windows offer superior ventilation and flexible functionality, customizable to complement any architectural style.",
      fabrication: "Horizon",

      numberOfLinks: 3,
      links: [
        "https://www.fenetreselite.com/en/horizon/modular-casement/",
        "https://www.fenetreselite.com/en/horizon/structural-casement/",
        "https://www.fenetreselite.com/en/horizon/5-58-4-38-integrated-brickmoulds-casement/",
      ],
    },

    {
      id: 1,
      type: "window",
      function: "Awning",
      img: awningWindow,
      briefDescription:
        "Stylish awning windows provide excellent ventilation, even during light rain, with a modern design that enhances both form and function.",
      fabrication: "Horizon",

      numberOfLinks: 3,
      links: [
        "https://www.fenetreselite.com/en/horizon/modular-awning/",
        "https://www.fenetreselite.com/en/horizon/auvent-structural/",
        "https://www.fenetreselite.com/en/horizon/integrated-brickmoulds-awning/",
      ],
    },

    {
      id: 2,
      type: "window",
      function: "Hung",
      img: hungSingleWindow,
      briefDescription:
        "Convenient hung windows come in single or double configurations, offering easy cleaning, improved airflow, and timeless charm.",
      fabrication: "Horizon",

      numberOfLinks: 2,
      links: [
        "https://www.fenetreselite.com/en/horizon/single-hung/",
        "https://www.fenetreselite.com/en/horizon/double-hung/",
      ],
    },

    {
      id: 3,
      type: "window",
      function: "Slider",
      img: sliderSingleWindow,
      imgInterior: sliderSingleWindowInterior,
      briefDescription:
        "Sleek slider windows offer space-saving benefits and effortless operation, perfect for modern spaces with tight constraints.",
      fabrication: "Horizon",

      numberOfLinks: 2,
      links: [
        "https://www.fenetreselite.com/en/horizon/single-slider/",
        "https://www.fenetreselite.com/en/horizon/double-slider/",
      ],
    },
    {
      id: 4,
      type: "door",
      function: "Entry Door",
      img: novatechDoor1,
      briefDescription:
        "Elevate your home's entrance with Novatech's premium entry doors, crafted with the highest quality materials for unmatched durability and timeless elegance.",
      numberOfLinks: 1,
      links: [
        "https://www.groupenovatech.com/en_canada_west/products/entry-doors.html",
      ],
    },

    {
      id: 5,
      type: "door",
      function: "Patio Door",
      img: novatechPatioDoor1,
      briefDescription:
        "Transform your living space with Novatech's stylish patio doors, engineered with top-quality materials to withstand the elements and bring the outdoors in.",
      numberOfLinks: 1,
      links: [
        "https://www.groupenovatech.com/en_canada_west/products/patio-doors.html",
      ],
    },
  ];

  return (
    <Box
      sx={{
        overflowX: "auto", // Enable horizontal scrolling
        display: "flex",
        flexDirection: "row", // Cards in a row
        padding: 1,
        "&::-webkit-scrollbar": {
          display: "none", // Optionally hide the scrollbar
        },
      }}
    >
      {productData.map((item, index) => (
        <Card
          key={index}
          sx={{
            minWidth: 200, // Minimum width for each card
            maxWidth: 300, // Maximum width for each card
            flex: "0 0 auto", // Prevent cards from shrinking
            margin: 1,
            boxShadow: 3, // More pronounced shadow
            height: "100%",
          }}
        >
          <CardMedia
            component="img"
            image={item.img}
            alt={item.function}
            sx={{ objectFit: "contain", width: "100%", height: "auto" }} // Adapt height to the image
          />
          <CardContent>
            <Typography gutterBottom variant="subtitle1" fontWeight="bold">
              {item.function}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default ProductDisplay;
