import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
} from "@mui/material";
import useFaq from "../hooks/use-faq/useFaq";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styled from "@emotion/styled";
import DOMPurify from "dompurify";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import ContactUs from "./ContactUs";

const StyledButton = styled.button({
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
});
const StyledTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: black;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #aa6800;
      }
    }
    & .MuiFormHelperText-root {
      color: red;
    }
    & .MuiInput-underline:after {
      border-bottom-color: #aa6800;
    }
  }
`;
const FAQ = () => {
  const { faqData } = useFaq();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { isDevice } = useScreenSizes();

  const filteredFAQs = faqData.filter((faq) =>
    faq.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {isDevice && (
        <>
          <Box
            p="0 1rem"
            margin="0 auto"
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Box>
              <StyledButton onClick={() => navigate(-1)}>
                <ArrowBackIcon style={{ color: "#000000" }} fontSize="large" />
              </StyledButton>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="subtitle1" fontWeight="bold">
                Frequently Asked Questions
              </Typography>
            </Box>
          </Box>
          <Box width="20rem" margin="0 auto">
            <StyledTextField
              label="Search FAQs"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Box>
          <Box display="flex" gap="1rem" flexDirection={"column"} p="1rem">
            {filteredFAQs.map((faq) => (
              <Card
                key={faq.id}
                sx={{
                  width: "370px",
                  boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                  margin: "0 auto",
                  borderRadius: "12px",
                  padding: "0.5rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {faq.title}
                  </Typography>

                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html:
                        DOMPurify.sanitize(faq.content).slice(0, 130) + "...",
                    }}
                  />
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    size="small"
                    sx={{ color: "#F3950D" }}
                    onClick={() => {
                      navigate(`/faq/${faq.id}`);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Typography variant="button">Read More</Typography>
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
        </>
      )}
      {!isDevice && (
        <>
          <Box
            margin="2rem auto 0 auto"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            textAlign={"center"}
          >
            <Box>
              <Typography variant="h2" fontWeight="bold">
                Frequently Asked Questions
              </Typography>
            </Box>
            <Box width="20rem" margin="0 auto">
              <StyledTextField
                label="Search FAQs"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
              />
            </Box>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns={"repeat(3, 370px)"}
            gap="4rem"
            p="1rem"
            justifyContent={"center"}
            mb="1rem"
          >
            {filteredFAQs.map((faq) => (
              <Card
                key={faq.id}
                sx={{
                  width: "370px",
                  boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                  margin: "0 auto",
                  borderRadius: "12px",
                  padding: "0.5rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {faq.title}
                  </Typography>

                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html:
                        DOMPurify.sanitize(faq.content).slice(0, 130) + "...",
                    }}
                  />
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    size="small"
                    sx={{ color: "#F3950D" }}
                    onClick={() => {
                      navigate(`/faq/${faq.id}`);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Typography variant="button">Read More</Typography>
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
          <ContactUs />
        </>
      )}
    </>
  );
};

export default FAQ;
