import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardMedia,
} from "@mui/material";
import styled from "@emotion/styled";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import image1 from "../assets/section2-5.jpg";
import carmineProfile from "../assets/carmine-profile.jpeg";
import CloseIcon from "@mui/icons-material/Close";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import HistoryIcon from "@mui/icons-material/History";
import BalanceIcon from "@mui/icons-material/Balance";
import PeopleIcon from "@mui/icons-material/People";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const StyledGetStartedButton = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  borderRadius: "30px",
});

const StyledShowRoomButton = styled(Button)({
  color: "#1E1F24",
  backgroundColor: "#F3950D",
  border: "1px solid #1E1F24",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#fefbf3",
  },
  borderRadius: "30px",
});

const StyledTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: black;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #aa6800;
      }
    }
    & .MuiFormHelperText-root {
      color: red;
    }
    & .MuiInput-underline:after {
      border-bottom-color: #aa6800;
    }
  }
`;

const StyledSubmitButton = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  borderRadius: "30px",
});

const About = () => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("2024-01-01");
  const [time, setTime] = useState("9:00AM");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const { isDevice } = useScreenSizes();
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEventTimeChange = (event: any) => {
    setTime(event.target.value);
  };

  const timeOptions = [
    "9:00AM",
    "9:15AM",
    "9:30AM",
    "9:45AM",
    "10:00AM",
    "10:15AM",
    "10:30AM",
    "10:45AM",
    "11:00AM",
    "11:15AM",
    "11:30AM",
    "11:45AM",
    "12:00PM",
    "12:15PM",
    "12:30PM",
    "12:45PM",
    "1:00PM",
    "1:15PM",
    "1:30PM",
    "1:45PM",
    "2:00PM",
    "2:15PM",
    "2:30PM",
    "2:45PM",
    "3:00PM",
    "3:15PM",
    "3:30PM",
    "3:45PM",
    "4:00PM",
    "4:15PM",
    "4:30PM",
    "4:45PM",
    "5:00PM",
    "5:15PM",
    "5:30PM",
    "5:45PM",
    "6:00PM",
  ];
  return (
    <>
      <Box
        m="2rem auto"
        p={isDevice ? " " : "0 6rem"}
        width={isDevice ? "100%" : "60%"}
      >
        <Box pb="3rem">
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"1rem"}
            p={isDevice ? "1rem " : "0"}
          >
            <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
              <Typography
                variant={isDevice ? "subtitle2" : "subtitle1"}
                color="#AA6800"
                m="1.5rem 0 -0.5rem 0"
                fontWeight="bold"
                textAlign={"left"}
              >
                ABOUT US
              </Typography>
              <Typography
                variant={isDevice ? "h6" : "h4"}
                color="#1E1F24"
                textAlign="left"
              >
                San Bruno Group: Elevating Homes, {!isDevice && <br />}{" "}
                Empowering Builders and Home owners
              </Typography>
              <Typography
                variant={isDevice ? "subtitle2" : "subtitle1"}
                color="#80828D"
                textAlign="left"
              >
                Your Complete Solution for {isDevice && <br />} Window and Door
                Excellence
              </Typography>
            </Box>

            <Box
              display={"flex"}
              gap={"1rem"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              m={isDevice ? "0 auto" : "1.5rem 0 0 auto"}
            >
              <Box
                width={isDevice ? "100%" : "500px"}
                m={isDevice ? "0 auto" : ""}
              >
                <img
                  src={image1}
                  style={{ width: "100%" }}
                  loading="lazy"
                  alt=""
                />
              </Box>
              <Box m="0 auto">
                <StyledGetStartedButton
                  variant="contained"
                  startIcon={<ArrowOutwardIcon />}
                  onClick={() => {
                    navigate("/get-a-quote");
                    window.scrollTo(0, 0);
                  }}
                >
                  <Typography variant="button">Get Started</Typography>
                </StyledGetStartedButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={"1rem"} p="1rem">
          <Box
            display={"flex"}
            flexDirection={isDevice ? "column" : "row"}
            mt={isDevice ? "1rem" : "2rem"}
            gap={isDevice ? "0.5rem" : "2rem"}
            justifyContent={"left"}
            alignItems={"center"}
          >
            <CardMedia
              component="img"
              image={carmineProfile}
              sx={{
                width: isDevice ? "200px" : "300px",
                height: isDevice ? "200px" : "300px",
                borderRadius: "50%",
                marginBottom: "1rem",
              }}
            />
            <Box>
              <Typography
                variant={isDevice ? "subtitle2" : "h6"}
                color="#AA6800"
                fontWeight="bold"
                textAlign={isDevice ? "center" : "left"}
              >
                Founder & CEO
              </Typography>
              <Typography variant="h4" textAlign={isDevice ? "center" : "left"}>
                Carmine De Leo
              </Typography>
              <Typography
                variant={isDevice ? "subtitle2" : "subtitle1"}
                color="#1E1F24"
                textAlign={isDevice ? "center" : "left"}
                sx={{
                  textDecoration: "italic",
                }}
                width={isDevice ? "100%" : "80%"}
              >
                With 45 years of industry experience, Carmine leads{" "}
                <strong style={{ color: "#AA6800" }}> San Bruno Group</strong>{" "}
                with a vision to redefine living and working spaces with
                excellence.
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mt="1rem"
          >
            <HistoryIcon sx={{ color: "#AA6800", fontSize: "3rem" }} />
            <Typography
              variant={isDevice ? "subtitle2" : "subtitle1"}
              color="#AA6800"
              fontWeight="bold"
              textAlign={"left"}
            >
              SAN BRUNO STORY
            </Typography>
          </Box>

          <Box width={isDevice ? "100%" : "80%"} m="0 auto">
            <Typography
              variant={isDevice ? "subtitle2" : "subtitle1"}
              color="#1E1F24"
              textAlign={"center"}
              lineHeight={isDevice ? "1.5rem" : "1.95rem"}
            >
              For over a decade, our dedicated team has been the trusted choice
              of builders, contractors, and homeowners who demand nothing but
              the best for their properties. At San Bruno Group, we specialize
              in providing Elite Windows and Doors, ensuring unparalleled
              service from selection to installation.
            </Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mt="1rem"
          >
            <BalanceIcon sx={{ color: "#AA6800", fontSize: "3rem" }} />
            <Typography
              variant={isDevice ? "subtitle2" : "subtitle1"}
              color="#AA6800"
              fontWeight="bold"
              textAlign={"center"}
            >
              OUR PHILOSOPHY
            </Typography>
          </Box>
          <Box width={isDevice ? "100%" : "80%"} m="0 auto">
            <Typography
              variant={isDevice ? "subtitle2" : "subtitle1"}
              color="#1E1F24"
              textAlign={"center"}
              lineHeight={isDevice ? "1.5rem" : "1.95rem"}
            >
              At the heart of San Bruno Group is a dedication to innovation and
              customer satisfaction. We understand that time is money in the
              construction world, which is why we guarantee zero issues with our
              installations and offer a rapid response team to address any
              challenges swiftly. From consultation to installation, our focus
              is on delivering exceptional quality and service.
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mt="1rem"
          >
            <VerifiedUserIcon sx={{ color: "#AA6800", fontSize: "3rem" }} />

            <Typography
              variant={isDevice ? "subtitle2" : "subtitle1"}
              color="#AA6800"
              fontWeight="bold"
              textAlign={"left"}
            >
              OUR PROMISE
            </Typography>
          </Box>

          <Box width={isDevice ? "100%" : "80%"} m="0 auto">
            <Typography
              variant={isDevice ? "subtitle2" : "subtitle1"}
              color="#1E1F24"
              textAlign={"center"}
              lineHeight={isDevice ? "1.5rem" : "1.95rem"}
            >
              When you choose San Bruno Group, you're choosing reliability,
              expertise, and peace of mind. Our comprehensive sales options
              cater to your needs, whether you require professional installation
              or post-installation inspections. We stand behind our work with
              confidence, offering comprehensive warranties and ongoing support.
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mt="1rem"
          >
            <PeopleIcon sx={{ color: "#AA6800", fontSize: "3rem" }} />

            <Typography
              variant={isDevice ? "subtitle2" : "subtitle1"}
              color="#AA6800"
              fontWeight="bold"
              textAlign={"left"}
            >
              OUR COMMUNITY
            </Typography>
          </Box>
          <Box width={isDevice ? "100%" : "80%"} m="0 auto">
            <Typography
              variant={isDevice ? "subtitle2" : "subtitle1"}
              color="#1E1F24"
              textAlign={"center"}
              lineHeight={isDevice ? "1.5rem" : "1.95rem"}
            >
              At San Bruno Group, we're not just building homes – we're building
              relationships. We're proud to be part of a vibrant community of
              builders, contractors, designers, and homeowners who share our
              passion for quality and craftsmanship. Together, we're shaping the
              future of spaces and creating environments that inspire.
            </Typography>
          </Box>

          <Box m="2rem auto">
            <StyledShowRoomButton
              variant="contained"
              startIcon={<ArrowOutwardIcon />}
              onClick={handleOpen}
            >
              <Typography variant="button">Visit Our Showroom</Typography>
            </StyledShowRoomButton>
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Visit Our Showroom
            </Typography>
            <Button onClick={handleClose} sx={{ color: "#1E1F24" }}>
              <CloseIcon />
            </Button>
          </Box>
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Box display="flex" gap="1rem" mt="1rem">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {isDevice && (
                  <MobileDatePicker
                    label="Date"
                    defaultValue={dayjs("2024-01-01")}
                    onChange={(selectedDate) => {
                      setDate(
                        selectedDate?.format("DD-MM-YYYY") ||
                          new Date().getDate().toString()
                      );
                    }}
                    value={dayjs(date)}
                  />
                )}
                {!isDevice && (
                  <DesktopDatePicker
                    label="Date"
                    defaultValue={dayjs(date)}
                    onChange={(selectedDate) => {
                      setDate(
                        selectedDate?.format("DD-MM-YYYY") ||
                          new Date().getDate().toString()
                      );
                    }}
                    value={dayjs(date)}
                  />
                )}
              </LocalizationProvider>
              <Box width="40%">
                <FormControl fullWidth>
                  <InputLabel
                    id="event-time-label"
                    sx={{
                      "&.Mui-focused": {
                        color: "#AA6800",
                      },
                    }}
                  >
                    Time
                  </InputLabel>
                  <Select
                    labelId="event-time-label"
                    id="event-time-select"
                    value={time}
                    label="Time"
                    onChange={handleEventTimeChange}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#AA6800",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#AA6800",
                      },
                    }}
                  >
                    {timeOptions.map((time) => (
                      <MenuItem key={time} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <StyledTextField
              margin="normal"
              fullWidth
              label="Message"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <Box display={"flex"} justifyContent={"left"} mt={"1rem"}>
              <StyledSubmitButton fullWidth endIcon={<ArrowOutwardIcon />}>
                <Typography variant="button">Submit</Typography>
              </StyledSubmitButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default About;
