import React from "react";
import styled, { keyframes } from "styled-components";
import { Box } from "@mui/material";
import eliteWindow from "../assets/elite-window.png";
import ancoHomes from "../assets/anco-logo.webp";
import uniformHomes from "../assets/uniform-homes.webp";
import hnHomes from "../assets/hn-homes-logo.png";
import novatech from "../assets/novatech-logo.webp";
import decko from "../assets/decko-logo.svg";
import cartesianHomes from "../assets/cartesian-homes.jpeg";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";

const moveText = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const CarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fefbf3;
  overflow-x: hidden;
`;

const CarouselBox = styled(Box)`
  display: inline-block;
  animation: 30s ${moveText} infinite linear;
  /* overflow-x: hidden; */
`;

const PartnerSlide = () => {
  const { isDevice } = useScreenSizes();
  return (
    <CarouselContainer>
      <CarouselBox width={isDevice ? "100%" : "80%"}>
        <Box display="flex" gap="2rem">
          <Box>
            <img
              src={eliteWindow}
              alt=""
              style={{ width: isDevice ? "150px" : "200px" }}
            />
          </Box>
          <Box>
            <img
              src={ancoHomes}
              alt=""
              style={{ width: isDevice ? "100px" : "150px" }}
            />
          </Box>
          <Box>
            <img
              src={uniformHomes}
              alt=""
              style={{ width: isDevice ? "150px" : "200px" }}
            />
          </Box>
          <Box>
            <img
              src={hnHomes}
              alt=""
              style={{ width: isDevice ? "100px" : "150px" }}
            />
          </Box>
          <Box>
            <img
              src={novatech}
              alt=""
              style={{
                width: isDevice ? "150px" : "200px",
                height: isDevice ? "100px" : "150px",
              }}
            />
          </Box>
          <Box>
            <img
              src={decko}
              alt=""
              style={{
                width: isDevice ? "150px" : "200px",
                height: isDevice ? "100px" : "100px",
              }}
            />
          </Box>
          <Box>
            <img
              src={cartesianHomes}
              alt=""
              style={{
                width: isDevice ? "150px" : "200px",
                height: isDevice ? "100px" : "100px",
              }}
            />
          </Box>
        </Box>
      </CarouselBox>
    </CarouselContainer>
  );
};

export default PartnerSlide;
