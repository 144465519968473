import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styled from "@emotion/styled";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import useFaq from "../hooks/use-faq/useFaq";

const StyledButton = styled.button({
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  paddingLeft: "0rem",
  paddingRight: "0rem",
});

const FaqDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { faqData } = useFaq();
  const navigate = useNavigate();

  const { isDevice } = useScreenSizes();

  const faqId = parseInt(id!);

  const faq = faqData.find((item) => item.id === faqId);

  if (!faq) {
    return <Typography variant="h6">FAQ not found</Typography>;
  }

  const createMarkup = (html: string) => {
    return { __html: html };
  };

  return (
    <Box padding={" 2rem"} display={"flex"} flexDirection={"column"} gap="1rem">
      {isDevice && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-evenly"}
            width={"100%"}
            gap="1rem"
          >
            <Box>
              <StyledButton onClick={() => navigate(-1)}>
                <ArrowBackIcon style={{ color: "#000000" }} fontSize="large" />
              </StyledButton>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="subtitle1" fontWeight="bold">
                {faq.title}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={createMarkup(faq.content)}
          />
        </>
      )}
      {!isDevice && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-evenly"}
            width={"100%"}
            gap="1rem"
          >
            <Box>
              <StyledButton onClick={() => navigate(-1)}>
                <ArrowBackIcon style={{ color: "#000000" }} fontSize="large" />
              </StyledButton>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h5" fontWeight="bold">
                {faq.title}
              </Typography>
            </Box>
          </Box>
          <Box width={"80%"}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={createMarkup(faq.content)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default FaqDetails;
