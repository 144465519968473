import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Snackbar,
  Slide,
} from "@mui/material";
import styled from "@emotion/styled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import validator from "validator";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import emailjs from "@emailjs/browser"; // Import EmailJS

const StyledNextButton = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  borderRadius: "30px",
});

const StyledCheckBox = styled(Checkbox)({
  color: "#E79C4A",
  "&.Mui-checked": {
    color: "#F3950D",
  },
});

const StyledTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: black;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #aa6800;
      }
    }
    & .MuiFormHelperText-root {
      color: red;
    }
    & .MuiInput-underline:after {
      border-bottom-color: #aa6800;
    }
  }
`;

const ProjectSection = () => {
  const [toggleNextButton, setToggleNextButton] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [windowCheck, setWindowCheck] = useState(false);
  const [doorCheck, setDoorCheck] = useState(false);
  const [serviceCheck, setServiceCheck] = useState(false);
  const [newConstructionCheck, setNewConstructionCheck] = useState(false);
  const [renovationCheck, setRenovationCheck] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [, setAttachment] = useState(null);

  const { isDevice } = useScreenSizes();

  const validateEmail = () => {
    if (validator.isEmail(email)) {
      return true;
    }
    return false;
  };

  const validatePhone = () => {
    if (validator.isMobilePhone(phone)) {
      return true;
    }
    return false;
  };

  const handleDisableSubmit = () => {
    if (!name || !email || !phone || !message) {
      return true;
    } else {
      return false;
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    const lookingForOptions = {
      option1: "Pricing On New Windows and Doors",
      option2: "Pricing On Complete Package Including Installation",
      option3: "Installation Only",
    };

    emailjs
      .send(
        "service_aoeasx8",
        "template_zwyw21o",
        {
          to_name: "San Bruno Group Sales Team", // Specify the employee's name
          from_name: name,
          from_email: email,
          from_phone: phone,
          from_address: address,
          looking_for: lookingForOptions["option1"], // Adjust based on selected option
          interested_windows: windowCheck ? "Yes" : "No",
          interested_doors: doorCheck ? "Yes" : "No",
          interested_services: serviceCheck ? "Yes" : "No",
          project_new_construction: newConstructionCheck ? "Yes" : "No",
          project_renovation: renovationCheck ? "Yes" : "No",
          message: message,
        },
        "PsuRtXsUAviDAgFSq"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setOpenSnackbar(true);

          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setAddress("");
          setWindowCheck(false);
          setDoorCheck(false);
          setServiceCheck(false);
          setNewConstructionCheck(false);
          setRenovationCheck(false);

          setToggleNextButton(false);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box textAlign={isDevice ? "center" : "left"} mt="3rem" id="project-section" p={"0 1rem"}>
        <Typography variant={isDevice ? "h6" : "h4"} color="#AA6800" fontWeight={700}>
          Tell us about your project
        </Typography>
        {isDevice && (
          <Divider
            variant={"inset"}
            sx={{
              backgroundColor: "#AA6800",
              height: "1px",
              marginTop: "0.25rem",
            }}
          />
        )}
      </Box>
      <Box mt="1rem" p={"0 1rem"}>
        <Typography variant={isDevice ? "body2" : "subtitle1"} color="#1E1F24" fontWeight={500}>
          Comprehensive range of solutions <br /> tailored for Architects, Builders, and Homeowners.
        </Typography>
      </Box>
      <Box p={"1rem"} display="flex" flexDirection="column" gap="2rem">
        {!toggleNextButton && (
          <>
            <Box>
              <InputLabel sx={{ color: "#1E1F24", marginBottom: "0.5rem" }}>
                <Typography variant="body1" fontWeight={600}>
                  I'm looking for
                </Typography>
              </InputLabel>
              <Select
                label="Pricing On New Windows and Doors"
                defaultValue="option1"
                variant="outlined"
                sx={{
                  ":after": {
                    borderBottomColor: "#aa6800",
                  },
                  "&.MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#aa6800",
                    },
                  },
                }}
              >
                <MenuItem value="option1">Pricing On New Windows and Doors</MenuItem>
                <MenuItem value="option2">Pricing On Complete Package Including Installation</MenuItem>
                <MenuItem value="option3">Installation Only</MenuItem>
              </Select>
            </Box>
            <Box>
              <InputLabel sx={{ color: "#1E1F24", marginBottom: "0.5rem" }}>
                <Typography variant="body1" fontWeight={600}>
                  I'm interested in
                </Typography>
              </InputLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <StyledCheckBox onClick={() => setWindowCheck(!windowCheck)} checked={windowCheck} />
                  }
                  label="Windows"
                />
                <FormControlLabel
                  control={<StyledCheckBox onClick={() => setDoorCheck(!doorCheck)} checked={doorCheck} />}
                  label="Doors"
                />
                <FormControlLabel
                  control={
                    <StyledCheckBox onClick={() => setServiceCheck(!serviceCheck)} checked={serviceCheck} />
                  }
                  label="Services"
                />
              </FormGroup>
            </Box>
            <Box>
              <InputLabel sx={{ color: "#1E1F24", marginBottom: "0.5rem" }}>
                <Typography variant="body1" fontWeight={600}>
                  My project is
                </Typography>
              </InputLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <StyledCheckBox
                      onClick={() => setNewConstructionCheck(!newConstructionCheck)}
                      checked={newConstructionCheck}
                    />
                  }
                  label="New Construction"
                />
                <FormControlLabel
                  control={
                    <StyledCheckBox
                      onClick={() => setRenovationCheck(!renovationCheck)}
                      checked={renovationCheck}
                    />
                  }
                  label="Renovation"
                />
              </FormGroup>
            </Box>
            <Box width={isDevice ? "100%" : "50%"}>
              <StyledNextButton
                fullWidth
                endIcon={<ArrowForwardIcon />}
                onClick={() => setToggleNextButton(true)}
              >
                <Typography variant="button">Next</Typography>
              </StyledNextButton>
            </Box>
          </>
        )}
        {toggleNextButton && (
          <>
            <Box display="flex" gap="1rem" flexDirection="column">
              <Box ml="-1rem">
                <Button
                  startIcon={
                    <KeyboardBackspaceIcon
                      sx={{
                        height: "30px",
                      }}
                      onClick={() => setToggleNextButton(false)}
                    />
                  }
                  variant="text"
                  sx={{
                    color: "#1E1F24",
                  }}
                />
              </Box>
              <Typography variant="subtitle1" fontWeight={600}>
                Let's talk!
              </Typography>
              <Typography variant="body2">
                Fill out the form below, and we'll get back to you as
                <br /> soon as possible.
              </Typography>
            </Box>

            <Box
              component="form"
              display="flex"
              flexDirection="column"
              gap="1rem"
              onSubmit={handleFormSubmit}
            >
              <StyledTextField
                id="standard-required"
                required
                label="Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <StyledTextField
                id="standard-required"
                required
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                helperText={validatePhone() || phone?.length === 0 ? "" : "Invalid Phone Number"}
              />
              <StyledTextField
                id="standard-required"
                required
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                helperText={validateEmail() || email?.length === 0 ? "" : "Invalid Email"}
              />
              <StyledTextField
                id="standard-required"
                label="Property Address"
                variant="outlined"
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <StyledTextField
                id="outlined"
                required
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <StyledTextField
                type="file"
                variant="outlined"
                fullWidth
                onChange={(e) => setAttachment((e.target as any).files[0])}
                inputProps={{ accept: ".pdf,.doc,.docx,.png,.jpg" }} // Specify accepted file types
                sx={{
                  mt: 3,
                  "& .MuiInputBase-input": {
                    cursor: "pointer",
                  },
                }}
              />
              <StyledNextButton
                fullWidth
                endIcon={<ArrowForwardIcon />}
                type="submit"
                disabled={handleDisableSubmit()}
                sx={{
                  ":disabled": {
                    backgroundColor: "#D8D8D8",
                  },
                }}
              >
                <Typography variant="button">Submit your request</Typography>
              </StyledNextButton>
            </Box>
            <Snackbar
              open={openSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              message={
                <Box display="flex" flexDirection={"column"} gap="0.25rem">
                  <CircleNotificationsIcon sx={{ color: "#F3950D", fontSize: 30 }} />
                  <Typography variant="overline">
                    {" "}
                    <span style={{ color: "#F3950D" }}>Request Sent</span> <br /> We will get back to you as
                    soon as possible.
                  </Typography>
                </Box>
              }
              onClose={() => setOpenSnackbar(false)}
              autoHideDuration={3000}
              TransitionComponent={(props) => <Slide {...props} direction="right" />}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProjectSection;
