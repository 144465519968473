import casementWindow from "../../assets/window-casement-white.png";
import casementWindowInterior from "../../assets/window-interior-cut-casement-white.jpeg";
import awningWindow from "../../assets/window-awing-white.png";
import hungSingleWindow from "../../assets/window-single-hung-white.png";
import sliderSingleWindow from "../../assets/window-single-slider-white.png";
import sliderSingleWindowInterior from "../../assets/window-interior-cut-single-slider-white.jpeg";
import casementHybrid from "../../assets/casement-hybrid.png";
import awningHybrid from "../../assets/awning-hybrid.png";
import hungHybrid from "../../assets/hung-hybrid.png";
import sliderHybrid from "../../assets/slider-hybrid.png";
import novatechDoor1 from "../../assets/novatech-door-black.webp";
import novatechDoor2 from "../../assets/novatech-door-white.webp";
import novatechPatioDoor1 from "../../assets/novatech-patio-door-grey.webp";
import deckoPatioDoor1 from "../../assets/decko-patio-door.png";

export const useProduct = () => {
  const productData = [
    {
      id: 0,
      type: "window",
      function: "Casement",
      img: casementWindow,
      imgInterior: casementWindowInterior,
      briefDescription:
        "Versatile casement windows offer superior ventilation and flexible functionality, customizable to complement any architectural style.",
      fabrication: "Horizon",

      numberOfLinks: 3,
      links: [
        "https://www.fenetreselite.com/en/horizon/modular-casement/",
        "https://www.fenetreselite.com/en/horizon/structural-casement/",
        "https://www.fenetreselite.com/en/horizon/5-58-4-38-integrated-brickmoulds-casement/",
      ],
    },

    {
      id: 1,
      type: "window",
      function: "Awning",
      img: awningWindow,
      briefDescription:
        "Stylish awning windows provide excellent ventilation, even during light rain, with a modern design that enhances both form and function.",
      fabrication: "Horizon",

      numberOfLinks: 3,
      links: [
        "https://www.fenetreselite.com/en/horizon/modular-awning/",
        "https://www.fenetreselite.com/en/horizon/auvent-structural/",
        "https://www.fenetreselite.com/en/horizon/integrated-brickmoulds-awning/",
      ],
    },

    {
      id: 2,
      type: "window",
      function: "Hung",
      img: hungSingleWindow,
      briefDescription:
        "Convenient hung windows come in single or double configurations, offering easy cleaning, improved airflow, and timeless charm.",
      fabrication: "Horizon",

      numberOfLinks: 2,
      links: [
        "https://www.fenetreselite.com/en/horizon/single-hung/",
        "https://www.fenetreselite.com/en/horizon/double-hung/",
      ],
    },

    {
      id: 3,
      type: "window",
      function: "Slider",
      img: sliderSingleWindow,
      imgInterior: sliderSingleWindowInterior,
      briefDescription:
        "Sleek slider windows offer space-saving benefits and effortless operation, perfect for modern spaces with tight constraints.",
      fabrication: "Horizon",

      numberOfLinks: 2,
      links: [
        "https://www.fenetreselite.com/en/horizon/single-slider/",
        "https://www.fenetreselite.com/en/horizon/double-slider/",
      ],
    },
    {
      id: 4,
      type: "window",
      function: "Casement",
      img: casementHybrid,
      imgInterior: sliderSingleWindowInterior,
      briefDescription:
        "Sleek slider windows offer space-saving benefits and effortless operation, perfect for modern spaces with tight constraints.",
      fabrication: "Hybrid",

      numberOfLinks: 1,
      links: ["https://www.fenetreselite.com/en/hybride/structural-casement/"],
    },
    {
      id: 5,
      type: "window",
      function: "Awning",
      img: awningHybrid,
      imgInterior: sliderSingleWindowInterior,
      briefDescription:
        "Sleek slider windows offer space-saving benefits and effortless operation, perfect for modern spaces with tight constraints.",
      fabrication: "Hybrid",

      numberOfLinks: 1,
      links: ["https://www.fenetreselite.com/en/hybride/structural-awning/"],
    },
    {
      id: 6,
      type: "window",
      function: "Hung",
      img: hungHybrid,
      imgInterior: sliderSingleWindowInterior,
      briefDescription:
        "Sleek slider windows offer space-saving benefits and effortless operation, perfect for modern spaces with tight constraints.",
      fabrication: "Hybrid",

      numberOfLinks: 2,
      links: [
        "https://www.fenetreselite.com/en/hybride/single-hung/",
        "https://www.fenetreselite.com/en/hybride/double-hung/",
      ],
    },
    {
      id: 7,
      type: "window",
      function: "Slider",
      img: sliderHybrid,
      imgInterior: sliderSingleWindowInterior,
      briefDescription:
        "Sleek slider windows offer space-saving benefits and effortless operation, perfect for modern spaces with tight constraints.",
      fabrication: "Hybrid",

      numberOfLinks: 2,
      links: [
        "https://www.fenetreselite.com/en/hybride/single-slider/",
        "https://www.fenetreselite.com/en/hybride/double-slider/",
      ],
    },
    {
      id: 8,
      type: "door",
      function: "Entry Door",
      img: novatechDoor1,
      briefDescription:
        "Elevate your home's entrance with Novatech's premium entry doors, crafted with the highest quality materials for unmatched durability and timeless elegance.",
      numberOfLinks: 1,
      links: [
        "https://www.groupenovatech.com/en_canada_west/products/entry-doors.html",
      ],
    },
    {
      id: 9,
      type: "door",
      function: "Entry Door",
      img: novatechDoor2,
      briefDescription:
        "Make a statement with Novatech's exquisite entry doors, designed to enhance your home's curb appeal while providing superior security and lasting performance.",
      numberOfLinks: 1,
      links: [
        "https://www.groupenovatech.com/en_canada_west/products/entry-doors.html",
      ],
    },
    {
      id: 10,
      type: "door",
      function: "Patio Door",
      img: novatechPatioDoor1,
      briefDescription:
        "Transform your living space with Novatech's stylish patio doors, engineered with top-quality materials to withstand the elements and bring the outdoors in.",
      numberOfLinks: 1,
      links: [
        "https://www.groupenovatech.com/en_canada_west/products/patio-doors.html",
      ],
    },
    {
      id: 11,
      type: "door",
      function: "Patio Door",
      img: deckoPatioDoor1,
      briefDescription:
        "Experience seamless indoor-outdoor living with Decko's premium patio doors, crafted for durability and beauty to complement any architectural style.",
      numberOfLinks: 1,
      links: ["https://www.portesdecko.com/en/produits/hybrid/"],
    },
  ];

  return {
    productData,
  };
};
