export const useFeedback = () => {
  const customerFeedback = [
    {
      id: 1,
      name: "Kathy Conlan",
      feedback: `I've known Carmine for over ten years. He renovated my aging family home and later my downsized bungalow, transforming both into bright, attractive homes that have drawn neighborhood compliments. Carmine and his team deliver creative, high-quality work with impeccable service. I highly recommend them.`,
    },
    {
      id: 2,
      name: "Satisfied Customer",
      feedback:
        "Wow! Is the simplest way to describe San Bruno Group's service and work. They went above and beyond to ensure every component of the service was done properly and with care. Their meticulous attention to detail even impressed our very particular architect neighbor, who sent us a photo of their setup. The windows look amazing. Trusting them to handle such a complex job while we were out of town was invaluable. Thank you again, San Bruno Group!",
    },
    {
      id: 3,
      name: "Satisfied Customer",
      feedback:
        "Excellent customer service from start to finish. San Bruno's team was knowledgeable, courteous, and went above and beyond to ensure we were satisfied with our new patio doors.",
    },
    {
      id: 4,
      name: "Satisfied Customer",
      feedback:
        "San Bruno Group offers top-notch solutions for any project. Their expertise in both design and functionality made choosing the perfect doors for our home effortless.",
    },
  ];

  return { customerFeedback };
};
