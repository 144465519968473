import React, { useState } from "react";
import { Box, Typography, ImageListItem, Modal } from "@mui/material";
import "./gallery.css";
import img1 from "../assets/ManorPark-Hobin-05-PRINT.jpg";
import img2 from "../assets/section2-2.jpg";
import img3 from "../assets/section2-5.jpg";
import img4 from "../assets/section2-4.jpg";
import img5 from "../assets/269-273Carruthers-29-PRINT.jpg";
import img6 from "../assets/P1_444Brennan-008.jpg";
import img7 from "../assets/001ext.jpg";
import img8 from "../assets/001ext1.jpg";
import img9 from "../assets/269-273Carruthers-17-PRINT.jpg";
import img10 from "../assets/273Dovercourt-09-GOHBA.jpg";
import img11 from "../assets/412Avondale-34b.jpg";
import img12 from "../assets/412Avondale-36.jpg";
import img13 from "../assets/598Roosevelt-ext01B-PRINT.jpg";
import img14 from "../assets/Back 4.jpg";
import img15 from "../assets/ManorPark-Hobin-02-PRINT.jpg";
import img16 from "../assets/ManorPark-Hobin-04-PRINT.jpg";
import img17 from "../assets/ManorPark-Hobin-22-PRINT.jpg";
import img18 from "../assets/P1_444Brennan-007.jpg";
import img19 from "../assets/P1_444Brennan-011.jpg";

const images = [
  {
    id: 0,
    img: img1,
  },
  {
    id: 1,
    img: img2,
  },
  {
    id: 2,
    img: img3,
  },
  {
    id: 3,
    img: img4,
  },
  {
    id: 4,
    img: img5,
  },
  {
    id: 5,
    img: img6,
  },
  {
    id: 6,
    img: img7,
  },
  {
    id: 7,
    img: img8,
  },
  {
    id: 8,
    img: img9,
  },
  {
    id: 9,
    img: img10,
  },
  {
    id: 10,
    img: img11,
  },
  {
    id: 11,
    img: img12,
  },
  {
    id: 12,
    img: img13,
  },
  {
    id: 13,
    img: img14,
  },
  {
    id: 14,
    img: img15,
  },
  {
    id: 15,
    img: img16,
  },
  {
    id: 16,
    img: img17,
  },
  {
    id: 17,
    img: img18,
  },
  {
    id: 18,
    img: img19,
  },
];

const Gallery = () => {
  const [modal, setModal] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const getImg = (img: string) => {
    setTempImgSrc(img);
    setModal(true);
  };

  return (
    <>
      <Box m="2rem 0">
        <Box textAlign={"center"} mb="2rem">
          <Typography variant="h3"> Our Gallery</Typography>
          <Typography
            variant="h6"
            sx={{ mt: "1rem", color: "#AA6800", opacity: 0.8 }}
            fontWeight="regular"
          >
            Inspiration Unveiled: Discover Endless Possibilities in Our Window
            and Door Gallery
          </Typography>
        </Box>
        <Box className="gallery" sx={{ columnCount: 3 }}>
          {images.map((item) => (
            <ImageListItem
              className="pics"
              key={item.id}
              onClick={() => getImg(item.img)}
            >
              <img
                src={`${item.img}`}
                alt={item.id.toString()}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </Box>
      </Box>
      <Modal
        open={modal}
        onClose={() => setModal(false)}
        sx={{
          width: "100%",
          height: "100%",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box width="800px">
          <img src={tempimgSrc} style={{ width: "100%" }} alt="" />
        </Box>
      </Modal>
    </>
  );
};

export default Gallery;
