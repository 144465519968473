import React from "react";
import { Box, Typography, CardMedia } from "@mui/material";
import { useBlogs } from "../hooks/use-blogs/useBlogs";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";

const Blogs = () => {
  const { blogData } = useBlogs();
  const { isDevice } = useScreenSizes();

  return (
    <Box
      padding="0 1rem"
      display="flex"
      flexDirection={"column"}
      gap="1rem"
      m="1rem auto"
      width={isDevice ? "90%" : "50%"}
      pb="1rem"
    >
      <Box mb="1rem">
        <Typography variant="h5" fontWeight={"bold"}>
          {blogData[0].title}
        </Typography>
        <Typography>
          {blogData[0].location}. {blogData[0].date}
        </Typography>
      </Box>
      <Box sx={{ height: "100%" }}>
        <CardMedia
          component="img"
          height="250"
          image={blogData[0].img}
          alt="Winter"
        />
      </Box>
      <Box>
        <Typography variant="body1">
          As seasoned professionals in the window industry, we have witnessed
          firsthand the importance of proper installation, glazing and sealing
          in new construction projects. Whether you're a homeowner or a builder,
          ensuring your windows are properly installed and sealed is crucial to
          prevent leakage issues down the line.
          <br />
          In this blog, we'll delve into the key strategies for sealing success,
          helping you avoid costly repairs and headaches in the future.
          <br />
          <br />
          <Typography fontWeight={"bold"} variant="subtitle1" mb="0.5rem">
            Quality Products Matter
          </Typography>{" "}
          It all starts with selecting high-quality windows and sealants. Sure,
          we all have a budget, but that budget can turn upside down if you end
          up with a water infiltration in your home. All of a sudden it is more
          than just your window: you have potential repairs to roofing, siding,
          framing, insulation, drywall, flooring...this is something no
          homeowner wants to experience. Opt for windows that are specifically
          designed and engineered for our climate and construction needs. Look
          for products with robust weather-stripping and durable frames to
          withstand the elements over time.
          <br />
          <br />
          <Typography fontWeight={"bold"} variant="subtitle1" mb="0.5rem">
            Attention to Detail During Installation
          </Typography>{" "}
          Proper installation is paramount in preventing window leakage.
          Builders and installers should meticulously follow manufacturer
          guidelines and best practices. This includes ensuring correct flashing
          installation, proper shimming, and precise caulking around the window
          perimeter using the proper sealant.
          <br />
          <br />
          <Typography fontWeight={"bold"} variant="subtitle1" mb="0.5rem">
            Flashing is Fundamental
          </Typography>{" "}
          Flashing acts as a barrier against water infiltration, diverting
          moisture away from vulnerable areas. Builders should use quality
          flashing materials and install them meticulously around windows,
          paying close attention to corners, sills, and headers. Properly
          installed flashing is your first line of defense against leaks. This
          is a special trade. Don't trust just anyone. <br />
          <br />
          <Typography fontWeight={"bold"} variant="subtitle1" mb="0.5rem">
            Sealant Selection and Application
          </Typography>{" "}
          Sealants play a critical role in sealing gaps and joints around
          windows. Choose high-quality sealants that are compatible with your
          window materials and substrate. When applying sealant, ensure thorough
          coverage along seams and edges, paying extra attention to areas prone
          to water intrusion. <br />
          <br />
          <Typography fontWeight={"bold"} variant="subtitle1" mb="0.5rem">
            Regular Inspections and Maintenance
          </Typography>{" "}
          Even with proper installation, it's essential to conduct regular
          inspections to identify any potential issues early on. Homeowners
          should inspect their windows annually for signs of wear, such as
          cracked caulking or damaged weather-stripping. Prompt repairs can help
          prevent minor issues from escalating into major leaks.
          <br />
          <br />
          <Typography fontWeight={"bold"} variant="subtitle1" mb="0.5rem">
            Invest in Professional Expertise
          </Typography>{" "}
          When in doubt, seek professional assistance. Experienced technicians
          and contractors can provide invaluable guidance on proper installation
          techniques and troubleshooting potential leakage issues. Investing in
          expert advice upfront can save you time and money in the long run.{" "}
          <br />
          <br />
          <Typography fontWeight={"bold"} variant="subtitle1" mb="0.5rem">
            Stay Informed and Educated
          </Typography>{" "}
          The construction industry is constantly evolving, with new
          technologies and best practices emerging regularly. Stay informed
          about the latest advancements in window sealing techniques and
          materials. Attend workshops, seminars, and industry events to expand
          your knowledge and stay ahead of the curve. <br /> <br /> In
          conclusion, preventing window leakage in new construction requires a
          combination of quality products, meticulous installation and
          finishing. By following these strategies, homeowners and builders can
          safeguard their investment and enjoy peace of mind knowing their
          windows are built to withstand the elements. <br /> <br /> Remember, a
          little attention to detail today can save you from costly repairs
          tomorrow, and when in doubt, ask a professional.
        </Typography>
      </Box>
      <Box display="flex" gap="1rem">
        <Box
          border={"1px solid black"}
          borderRadius={"12px"}
          textAlign={"center"}
          display={"flex"}
          alignItems={"center"}
          p="0.5rem"
          bgcolor="#F2F2F2"
        >
          <Typography variant="caption" fontWeight={"bold"} color={"black"}>
            Windows
          </Typography>
        </Box>
        <Box
          border={"1px solid black"}
          borderRadius={"12px"}
          textAlign={"center"}
          display={"flex"}
          alignItems={"center"}
          p="0.5rem"
          bgcolor="#F2F2F2"
        >
          <Typography variant="caption" fontWeight={"bold"} color={"black"}>
            Services
          </Typography>
        </Box>
        <Box
          border={"1px solid black"}
          borderRadius={"12px"}
          textAlign={"center"}
          display={"flex"}
          alignItems={"center"}
          p="0.5rem"
          bgcolor="#F2F2F2"
        >
          <Typography variant="caption" fontWeight={"bold"} color="black">
            Leakage Prevention
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Blogs;
