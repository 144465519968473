import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography, Link } from "@mui/material";
import logo from "../assets/fullcolor.svg";
import facebookLogo from "../assets/icons8-facebook.svg";
import linkedinLogo from "../assets/icons8-linkedin.svg";
import youtubeLogo from "../assets/icons8-youtube.svg";
import instagramLogo from "../assets/iconmonstr-instagram-11.svg";
import canadianFlag from "../assets/Flag_of_Canada.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";

const Footer = () => {
  const navigate = useNavigate();
  const { isDevice } = useScreenSizes();

  return (
    <Box bgcolor="#393232" p="1rem 1rem">
      <Box mb="1rem" alignItems={"center"}>
        <Typography
          variant="subtitle1"
          color="white"
          fontWeight="700"
          display="inline-block"
          fontFamily={"Fraunces"}
        >
          SAN BRUNO GROUP
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" color="white">
          Our team has devoted more than a decade to providing service exclusively to builders,{" "}
          {!isDevice && <br />} contractors and homeowners who have chosen Elite Windows and Doors for their
          homes and products.
        </Typography>
        <Box display="flex" alignItems={"center"} gap="0.25rem" mt="1rem">
          <Box>
            <img src={canadianFlag} alt="" style={{ width: "70px" }} />
          </Box>
          <Typography variant="body2" color="white" fontWeight="bold">
            Proudly Canadian
          </Typography>
        </Box>
      </Box>
      <Box display={isDevice ? "" : "flex"} justifyContent={isDevice ? "" : "space-evenly"} mt="3rem">
        {isDevice && (
          <Box display="flex" gap="5rem" mt="3rem">
            <Box
              textAlign="left"
              display={"flex"}
              flexDirection={"column"}
              justifyContent="left"
              gap="0.5rem"
              width="10rem"
            >
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/products`);
                  window.scrollTo(0, 0);
                }}
              >
                <Typography variant="subtitle1" color="white" fontWeight="bold">
                  Product
                </Typography>
              </Link>
              <Link
                style={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/products/window`);
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular" textAlign={"left"}>
                  Windows
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/products/door`);
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular">
                  Doors
                </Typography>
              </Link>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap="0.5rem">
              <Typography variant="subtitle1" color="white" fontWeight="bold">
                Company
              </Typography>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/about`);
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular" textAlign={"left"}>
                  About Us
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(`/faqs`);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular">
                  FAQs
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate("/warranty");
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular">
                  Warranty
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate("/our-process");
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular">
                  Our Process
                </Typography>
              </Link>
            </Box>
          </Box>
        )}

        {!isDevice && (
          <>
            <Box
              textAlign="left"
              display={"flex"}
              flexDirection={"column"}
              justifyContent="left"
              gap="0.5rem"
              width="70%"
            >
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/products`);
                  window.scrollTo(0, 0);
                }}
              >
                <Typography variant="subtitle1" color="white" fontWeight="bold">
                  Product
                </Typography>
              </Link>
              <Link
                style={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/products/window`);
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular" textAlign={"left"}>
                  Windows
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/products/door`);
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular">
                  Doors
                </Typography>
              </Link>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap="0.5rem" width="70%">
              <Typography variant="subtitle1" color="white" fontWeight="bold">
                Company
              </Typography>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/about`);
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular" textAlign={"left"}>
                  About Us
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(`/faqs`);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular">
                  FAQs
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate("/warranty");
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular">
                  Warranty
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  navigate("/our-process");
                  window.scrollTo(0, 0);
                }}
              >
                <Typography color="white" variant="body1" fontWeight="regular">
                  Our Process
                </Typography>
              </Link>
            </Box>
          </>
        )}

        <Box width={"100%"}>
          <Link
            sx={{ textDecoration: "none", cursor: "pointer" }}
            onClick={() => {
              navigate(`/services`);
              window.scrollTo(0, 0);
            }}
          >
            <Box display={"flex"} flexDirection={"column"} gap="0.5rem" mt={isDevice ? "2rem" : "0"}>
              <Typography variant="subtitle1" color="white" fontWeight="bold">
                Services
              </Typography>
              <Typography color="white" variant="body1" fontWeight="regular" textAlign={"left"}>
                Service Guide
              </Typography>
              <Typography color="white" variant="body1" fontWeight="regular" textAlign={"left"}>
                Full Sales and Installation Team
              </Typography>
              <Typography color="white" variant="body1" fontWeight="regular">
                Sales Only - Curbside pick up
              </Typography>
              <Typography color="white" variant="body1" fontWeight="regular">
                Installation Only
              </Typography>
              <Typography color="white" variant="body1" fontWeight="regular">
                Consulting and Troubleshooting
              </Typography>
            </Box>
          </Link>
        </Box>

        <Box
          textAlign="left"
          display={"flex"}
          flexDirection={"column"}
          justifyContent="left"
          gap="0.5rem"
          width={isDevice ? "20rem" : "100%"}
          mt={isDevice ? "2rem" : "0"}
        >
          <Typography variant="subtitle1" color="white" fontWeight="bold">
            Contact Us
          </Typography>
          <Link href="tel:6132967866" style={{ textDecoration: "none", color: "white" }}>
            <Typography color="white" variant="body1" fontWeight="regular" textAlign={"left"}>
              <strong>Phone:</strong> (613)-296-7866
            </Typography>
          </Link>

          <Link href="mailto:Info@sanbrunogroup.ca" style={{ textDecoration: "none", color: "white" }}>
            <Typography color="white" variant="body1" fontWeight="regular">
              <strong>Sales & Services:</strong> Info@sanbrunogroup.ca
            </Typography>
          </Link>
          <Link sx={{ textDecoration: "none" }}>
            <Typography color="white" variant="body1" fontWeight="regular">
              44b Grenfell Cres. <br />
              Nepean, Ontario, Canada
            </Typography>
          </Link>
        </Box>
        <Box
          textAlign="left"
          display={"flex"}
          flexDirection={"column"}
          justifyContent="left"
          gap="1rem"
          width={isDevice ? "100%" : "100%"}
          mt={isDevice ? "2rem" : "0"}
        >
          <Typography variant="subtitle1" color="white" fontWeight="bold">
            Latest blog post
          </Typography>
          <Box>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/blogs`);
              }}
              underline="none"
              color="inherit"
              sx={{ textDecoration: "none", cursor: "pointer" }}
            >
              <Typography color="white" variant="body1" fontWeight="bold" textAlign={"left"}>
                Sealing Success: Preventing Window Leakage in New Construction{" "}
                <ArrowOutwardIcon sx={{ color: "white" }} />
              </Typography>
              <Typography color="white" variant="body1" fontWeight="regular">
                May 4, 2024
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        m={isDevice ? "2rem 0 0 0" : "2rem 0 0 auto"}
        gap="1rem"
        width={isDevice ? "100%" : "20%"}
      >
        <Box mt="2rem" display={"flex"} flexDirection={"column"} gap="1rem">
          <Typography variant="subtitle1" color="white" fontWeight="bold">
            Follow Us
          </Typography>
          <Box display={"flex"} gap="0.35rem">
            <Link
              href="https://www.facebook.com/profile.php?id=61560438697499"
              target="_blank"
              sx={{ textDecoration: "none", cursor: "pointer" }}
            >
              <img src={facebookLogo} alt="facebook" />
            </Link>
            <Link
              sx={{ textDecoration: "none", cursor: "pointer" }}
              href="https://www.linkedin.com/company/san-bruno-group/"
              target="_blank"
            >
              <img src={linkedinLogo} alt="linkedin" />
            </Link>
            <Link
              sx={{ textDecoration: "none", cursor: "pointer" }}
              target="_blank"
              href="https://www.instagram.com/sanbrunogroup/"
            >
              <img src={instagramLogo} alt="instagram" />
            </Link>
            <Link
              sx={{ textDecoration: "none", cursor: "pointer" }}
              target="_blank"
              href="https://www.youtube.com/channel/UCnxDtbJUwal75yRBldiVW8Q"
            >
              <img src={youtubeLogo} alt="youtube" />
            </Link>
          </Box>
        </Box>
        <Box width="200px">
          <img src={logo} alt="" />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
