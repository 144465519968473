import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { useFeedback } from "../hooks/use-feedback/useFeedback";
import StarIcon from "@mui/icons-material/Star";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";

const CustomerReview = () => {
  const { customerFeedback } = useFeedback();
  const { isDevice } = useScreenSizes();
  return (
    <Box padding={"2rem 1rem"} mt="2rem" display="flex" gap="1rem" flexDirection={"column"}>
      <Typography variant={isDevice ? "h5" : "h3"}>Hear from our customers</Typography>
      <Typography variant={isDevice ? "body1" : "subtitle1"}>
        Discover what our valued customers have to say about their experiences with us. <br /> Read their
        testimonials below and learn why they trust us for their needs.
      </Typography>
      <Box display="flex" gap="1rem" width="100%" sx={{ overflowX: "auto" }}>
        <Box m="0.5rem 0">
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#EFF0F3",
              width: isDevice ? "200px" : "350px",
              height: isDevice ? "200px" : "350px",
              padding: "0.5rem",
              borderRadius: "12px",
            }}
            variant="outlined"
          >
            <Typography variant={isDevice ? "h6" : "h4"} lineHeight={isDevice ? "1.75rem" : "2rem"}>
              Don't take <span style={{ color: "#E79C4A" }}>our word</span> for it-{" "}
              <span style={{ color: "#E79C4A" }}>customers</span> say it best
            </Typography>
          </Card>
        </Box>
        {customerFeedback.map((feedback) => (
          <Box m="0.5rem 0" key={feedback.id} display={"flex"} alignItems={"center"}>
            <Card
              key={feedback.id}
              sx={{
                width: isDevice ? "300px" : "400px",
                boxShadow: "0px 4px 4px #E0E1E6",
                borderRadius: "12px",
                height: "100%",
              }}
              variant="outlined"
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "left",
                  mt: "3rem",
                }}
              >
                <Typography variant={isDevice ? "body1" : "subtitle2"}>{feedback.feedback}</Typography>
                <Box>
                  <StarIcon sx={{ color: "#E79C4A" }} />
                  <StarIcon sx={{ color: "#E79C4A" }} />
                  <StarIcon sx={{ color: "#E79C4A" }} />
                  <StarIcon sx={{ color: "#E79C4A" }} />
                  <StarIcon sx={{ color: "#E79C4A" }} />
                </Box>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomerReview;
