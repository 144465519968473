import React from "react";
import { Box } from "@mui/material";
import { useTransition, animated } from "@react-spring/web";
// import loadingImage1 from "../assets/white.svg";
// import loadingImage2 from "../assets/red.svg";
// import loadingImage3 from "../assets/halfyellow.svg";
import loadingImage4 from "../assets/fullcolor.svg";

const IntroAnimation = () => {
  const hearts = [
    {
      id: 1,
      src: loadingImage4,
    },
  ];

  const transitions = useTransition(hearts, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: { tension: 200, friction: 100, duration: 1000 },
    unique: true,
  });

  return (
    <Box
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#000000",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      {transitions((style, item) => (
        <animated.img
          style={{
            ...style,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            height: "400px",
          }}
          key={item.id}
          src={item.src}
          alt={`Lion ${item.id}`}
        />
      ))}
    </Box>
  );
};

export default IntroAnimation;
