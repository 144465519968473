import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Snackbar,
  Slide,
} from "@mui/material";
import styled from "@emotion/styled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import validator from "validator";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import img from "../assets/ManorPark-Hobin-22-PRINT.jpg";

const StyledNextButton = styled(Button)({
  color: "#1E1F24",
  border: "1px solid #1E1F24",
  backgroundColor: "#F3950D",
  "&:hover": {
    backgroundColor: "white",
    color: "#000000",
  },
  borderRadius: "30px",
});

const StyledCheckBox = styled(Checkbox)({
  color: "#E79C4A",
  "&.Mui-checked": {
    color: "#F3950D",
  },
});

const StyledTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: black;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #aa6800;
      }
    }
    & .MuiFormHelperText-root {
      color: red;
    }
    & .MuiInput-underline:after {
      border-bottom-color: #aa6800;
    }
  }
`;

const GetAQuote = () => {
  const [toggleNextButton, setToggleNextButton] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [windowCheck, setWindowCheck] = useState(false);
  const [doorCheck, setDoorCheck] = useState(false);
  const [serviceCheck, setServiceCheck] = useState(false);
  const [newConstructionCheck, setNewConstructionCheck] = useState(false);
  const [renovationCheck, setRenovationCheck] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [, setAttachment] = useState(null);

  const { isDevice } = useScreenSizes();

  const validateEmail = () => {
    if (validator.isEmail(email)) {
      return true;
    }
    return false;
  };

  const validatePhone = () => {
    if (validator.isMobilePhone(phone)) {
      return true;
    }
    return false;
  };

  const handleDisableSubmit = () => {
    if (!name || !email || !phone || !message) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box
      display={isDevice ? "" : "flex"}
      gap={isDevice ? "0" : "1rem"}
      justifyContent={isDevice ? "" : "center"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={isDevice ? "100%" : "30%"}
      >
        <Box textAlign={isDevice ? "center" : "left"} mt="3rem" p={"0 1rem"}>
          <Typography
            variant={isDevice ? "h6" : "h4"}
            color="#AA6800"
            fontWeight={700}
          >
            Tell us about your project
          </Typography>
          {isDevice && (
            <Divider
              variant={"inset"}
              sx={{
                backgroundColor: "#AA6800",
                height: "1px",
                marginTop: "0.25rem",
              }}
            />
          )}
        </Box>
        <Box mt="1rem" p={"0 1rem"}>
          <Typography
            variant={isDevice ? "body2" : "subtitle1"}
            color="#1E1F24"
            fontWeight={500}
          >
            Comprehensive range of solutions <br /> tailored for Architects,
            Builders, and Homeowners.
          </Typography>
        </Box>
        <Box p={"1rem"} display="flex" flexDirection="column" gap="2rem">
          {!toggleNextButton && (
            <>
              <Box>
                <InputLabel sx={{ color: "#1E1F24", marginBottom: "0.5rem" }}>
                  <Typography variant="body1" fontWeight={600}>
                    I'm looking for
                  </Typography>
                </InputLabel>
                <Select
                  label="Pricing On New Windows and Doors"
                  defaultValue="option1"
                  variant="outlined"
                  sx={{
                    ":after": {
                      borderBottomColor: "#aa6800",
                    },
                    "&.MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#aa6800",
                      },
                    },
                  }}
                >
                  <MenuItem value="option1">
                    Pricing On New Windows and Doors
                  </MenuItem>
                  <MenuItem value="option2">
                    Pricing On Complete Package Inlcuding Installation
                  </MenuItem>
                  <MenuItem value="option3">Installation Only</MenuItem>
                </Select>
              </Box>
              <Box>
                <InputLabel sx={{ color: "#1E1F24", marginBottom: "0.5rem" }}>
                  <Typography variant="body1" fontWeight={600}>
                    I'm interested in
                  </Typography>
                </InputLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <StyledCheckBox
                        onClick={() => setWindowCheck(!windowCheck)}
                        checked={windowCheck}
                      />
                    }
                    label="Windows"
                  />
                  <FormControlLabel
                    control={
                      <StyledCheckBox
                        onClick={() => setDoorCheck(!doorCheck)}
                        checked={doorCheck}
                      />
                    }
                    label="Doors"
                  />
                  <FormControlLabel
                    control={
                      <StyledCheckBox
                        onClick={() => setServiceCheck(!serviceCheck)}
                        checked={serviceCheck}
                      />
                    }
                    label="Services"
                  />
                </FormGroup>
              </Box>
              <Box>
                <InputLabel sx={{ color: "#1E1F24", marginBottom: "0.5rem" }}>
                  <Typography variant="body1" fontWeight={600}>
                    My project is
                  </Typography>
                </InputLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <StyledCheckBox
                        onClick={() =>
                          setNewConstructionCheck(!newConstructionCheck)
                        }
                        checked={newConstructionCheck}
                      />
                    }
                    label="New Construction"
                  />
                  <FormControlLabel
                    control={
                      <StyledCheckBox
                        onClick={() => setRenovationCheck(!renovationCheck)}
                        checked={renovationCheck}
                      />
                    }
                    label="Renovation"
                  />
                </FormGroup>
              </Box>
              <Box width={isDevice ? "100%" : "30%"}>
                <StyledNextButton
                  fullWidth
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => setToggleNextButton(true)}
                >
                  <Typography variant="button">Next</Typography>
                </StyledNextButton>
              </Box>
            </>
          )}
          {toggleNextButton && (
            <>
              <Box display="flex" gap="1rem" flexDirection="column">
                <Box ml="-1rem">
                  <Button
                    startIcon={
                      <KeyboardBackspaceIcon
                        sx={{
                          height: "30px",
                        }}
                        onClick={() => setToggleNextButton(false)}
                      />
                    }
                    variant="text"
                    sx={{
                      color: "#1E1F24",
                    }}
                  />
                </Box>
                <Typography variant="subtitle1" fontWeight={600}>
                  Let's talk!
                </Typography>
                <Typography variant="body2">
                  Fill out the form below, and we'll get back to you as
                  <br /> soon as possible.
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                gap="1rem"
                width={"100%"}
              >
                <StyledTextField
                  id="standard-required"
                  fullWidth
                  required
                  label="Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <StyledTextField
                  id="standard-required"
                  fullWidth
                  required
                  label="Phone Number"
                  variant="outlined"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  helperText={
                    validatePhone() || phone?.length === 0
                      ? ""
                      : "Invalid Phone Number"
                  }
                />
                <StyledTextField
                  id="standard-required"
                  fullWidth
                  required
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  helperText={
                    validateEmail() || email?.length === 0
                      ? ""
                      : "Invalid Email"
                  }
                />
                <StyledTextField
                  id="standard-required"
                  fullWidth
                  label="Property Address"
                  variant="outlined"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <StyledTextField
                  id="outlined"
                  fullWidth
                  required
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <StyledTextField
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setAttachment((e.target as any).files[0])}
                  inputProps={{ accept: ".pdf,.doc,.docx,.png,.jpg" }} // Specify accepted file types
                  sx={{
                    mt: 3,
                    "& .MuiInputBase-input": {
                      cursor: "pointer",
                    },
                  }}
                />
                <StyledNextButton
                  fullWidth
                  endIcon={<ArrowForwardIcon />}
                  disabled={handleDisableSubmit()}
                  sx={{
                    ":disabled": {
                      backgroundColor: "#D8D8D8",
                    },
                  }}
                  onClick={() => setOpenSnackbar(true)}
                >
                  <Typography variant="button">Submit your request</Typography>
                </StyledNextButton>
              </Box>
              <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                message={
                  <Box display="flex" flexDirection={"column"} gap="0.25rem">
                    <CircleNotificationsIcon
                      sx={{ color: "#F3950D", fontSize: 30 }}
                    />
                    <Typography variant="overline">
                      {" "}
                      <span style={{ color: "#F3950D" }}>
                        Request Sent
                      </span>{" "}
                      <br /> We will get back to you as soon as possible.
                    </Typography>
                  </Box>
                }
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={3000}
                TransitionComponent={(props) => (
                  <Slide {...props} direction="right" />
                )}
              />
            </>
          )}
        </Box>
      </Box>
      {!isDevice && (
        <Box
          width="600px"
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <img src={img} style={{ width: "600px", height: "600px" }} alt="" />
        </Box>
      )}
    </Box>
  );
};

export default GetAQuote;
