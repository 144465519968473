import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import eliteWindow from "../assets/elite-window.png";
import decko from "../assets/decko-logo.svg";
import novatech from "../assets/novatech-logo.webp";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import ContactUs from "./ContactUs";

const Warranty = () => {
  const warrantyInformation = [
    {
      id: 1,
      company: "Elite",
      duration: "Lifetime",
      img: eliteWindow,
      link: "https://www.fenetreselite.com/en/warranties/",
      highlights:
        "Comprehensive coverage for product components and lifetime protection against glass seal failure.",
    },
    {
      id: 2,
      company: "Decko",
      duration: "Limited lifetime",
      img: decko,
      link: "https://www.portesdecko.com/wp-content/uploads/2023/05/Warranty.pdf",
      highlights:
        "Lifetime coverage for vinyl window frames and hardware, ensuring quality and reliability.",
    },
    {
      id: 3,
      company: "Novatech",
      duration: "Limited lifetime",
      img: novatech,
      link: "https://www.groupenovatech.com/en_canada_west/guaranty.html",
      highlights:
        "Lifetime warranty on vinyl window frames and insulated glass units, providing ongoing performance and protection.",
    },
  ];

  const { isDevice } = useScreenSizes();
  return (
    <Box>
      <Box
        display="flex"
        gap="1rem"
        flexDirection={"column"}
        p="1rem"
        mb={"2rem"}
      >
        <Typography variant={isDevice ? "h6" : "h1"} textAlign={"center"}>
          Warranty Information
        </Typography>
        <Typography
          variant={isDevice ? "body1" : "h6"}
          fontWeight={"regular"}
          textAlign={"center"}
          width={isDevice ? "100%" : "50%"}
          m={isDevice ? "" : "0 auto 2rem auto"}
          color="#AA6800"
          sx={{ opacity: "0.8" }}
        >
          Our warranties are designed to protect your investment.{" "}
          {!isDevice && <br />} Discover the Long-Term Assurance and Highlights
          of Elite, Decko, and Novatech Warranties.
        </Typography>
        <Box
          display={"flex"}
          flexDirection={isDevice ? "column" : "row"}
          gap="1rem"
        >
          {warrantyInformation.map((warranty) => (
            <Card
              key={warranty.id}
              sx={{
                width: "370px",
                boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                margin: "0 auto",
                borderRadius: "12px",
                padding: "1rem",
                height: "280px",
              }}
            >
              <CardMedia
                component="img"
                image={warranty.img}
                sx={{ width: "250px" }}
              />
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Typography variant="body1" fontWeight="bold">
                  {warranty.highlights}
                </Typography>
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="small"
                  sx={{ color: "#F3950D" }}
                  href={warranty.link}
                >
                  <Typography variant="button">Read More</Typography>
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      </Box>
      <Box>
        <ContactUs />
      </Box>
    </Box>
  );
};

export default Warranty;
