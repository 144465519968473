import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Link,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useBlogs } from "../hooks/use-blogs/useBlogs";

const BlogSection = () => {
  const { blogData } = useBlogs();
  const navigate = useNavigate();

  return (
    <Box
      padding="0 1rem"
      display="flex"
      flexDirection={"column"}
      gap="1rem"
      mb="2rem"
    >
      <Box mb="1rem">
        <Typography variant="h6" fontWeight={"bold"}>
          Recent Blog Post
        </Typography>
      </Box>
      <Card sx={{ height: "100%" }} variant="outlined">
        <CardMedia
          component="img"
          height="250"
          image={blogData[0].img}
          alt="Winter"
        />
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            navigate(`/blogs`);
          }}
          underline="none"
          color="inherit"
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              cursor: "pointer",
            }}
          >
            <Typography>
              {blogData[0].location}. {blogData[0].date}
            </Typography>
            <Box display="flex" justifyContent="space-between" gap="1rem">
              <Typography variant="subtitle2" fontWeight="bold">
                {blogData[0].title}
              </Typography>
              <Box m="0 0 auto 0">
                <ArrowOutwardIcon sx={{ color: "#1E1F24" }} />
              </Box>
            </Box>
            <Box>
              <Typography>{blogData[0].content.slice(0, 149)}..</Typography>
            </Box>
            <Box display="flex" gap="1rem">
              <Box
                border={"1px solid black"}
                borderRadius={"12px"}
                textAlign={"center"}
                display={"flex"}
                alignItems={"center"}
                p="0.35rem"
              >
                <Typography
                  variant="caption"
                  fontWeight={"bold"}
                  color={"black"}
                >
                  Services
                </Typography>
              </Box>
              <Box
                border={"1px solid black"}
                borderRadius={"12px"}
                textAlign={"center"}
                display={"flex"}
                alignItems={"center"}
                p="0.35rem"
              >
                <Typography variant="caption" fontWeight={"bold"} color="black">
                  Leakage Prevention
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Link>
      </Card>
    </Box>
  );
};

export default BlogSection;
